import React, { useState } from 'react'
import { Linkedin, X } from 'lucide-react'
import styled from 'styled-components'

// Styled Components
const LinkedInButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #c859ff;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #004182;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const PopupContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  position: relative;
`

const PopupTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #2d3748;
`

const PopupMessage = styled.p`
  color: #4a5568;
  margin-bottom: 1.5rem;
  line-height: 1.5;
`

const CloseButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #c859ff;
  }
`

const LinkedInImport = ({ onDataImported }) => {
  const [showPopup, setShowPopup] = useState(false)

  const handleLinkedInImport = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <div>
      <LinkedInButton onClick={handleLinkedInImport}>
        <Linkedin size={18} />
        Import from LinkedIn
      </LinkedInButton>

      {showPopup && (
        <PopupOverlay onClick={closePopup}>
          <PopupContent onClick={(e) => e.stopPropagation()}>
            <PopupTitle>Feature Unavailable</PopupTitle>
            <PopupMessage>
              This feature is currently unavailable. Please contact support at
              support@arenatalent.com for additional support.
            </PopupMessage>
            <CloseButton onClick={closePopup}>Close</CloseButton>
          </PopupContent>
        </PopupOverlay>
      )}
    </div>
  )
}

export default LinkedInImport
