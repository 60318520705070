import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  doc,
  getDoc
} from 'firebase/firestore'
import { db } from '../../firebase'

// Admin key
const ADMIN_KEY = 'WelcomeToArena24!'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  height: calc(100vh - 150px);
`

const SeekerList = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
`

const DetailView = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
`

const SeekerCard = styled.div`
  background: ${(props) =>
    props.selected ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  h4 {
    margin: 0 0 8px 0;
    color: white;
  }

  p {
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
  }
`

const SectionTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
`

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

const PaginationButton = styled.button`
  padding: 10px;
  background: #8b5cf6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: default;
  }
`

const TotalCount = styled.h4`
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
`

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: white;
  margin-bottom: 15px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
  }
`

const SkillTag = styled.span`
  display: inline-block;
  background: rgba(139, 92, 246, 0.2);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 8px 8px 0;
`

const DetailSection = styled.div`
  margin-bottom: 20px;

  h4 {
    color: white;
    margin-bottom: 8px;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    margin: 4px 0;
  }
`

const JobSeekersTab = () => {
  const [jobSeekers, setJobSeekers] = useState([])
  const [filteredSeekers, setFilteredSeekers] = useState([])
  const [selectedSeeker, setSelectedSeeker] = useState(null)
  const [lastVisible, setLastVisible] = useState(null)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalSeekers, setTotalSeekers] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const ADMIN_KEY = 'WelcomeToArena24!'

  const pageSize = 20

  useEffect(() => {
    fetchJobSeekers()
    fetchTotalJobSeekerCount()
  }, [])

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredSeekers(jobSeekers)
    } else {
      const lowercasedTerm = searchTerm.toLowerCase()
      const filtered = jobSeekers.filter(
        (seeker) =>
          (seeker.first_name + ' ' + seeker.last_name)
            .toLowerCase()
            .includes(lowercasedTerm) ||
          seeker.email.toLowerCase().includes(lowercasedTerm)
      )
      setFilteredSeekers(filtered)
    }
  }, [searchTerm, jobSeekers])

  const fetchJobSeekers = async (isNextPage = false) => {
    setLoading(true)

    try {
      const seekersRef = collection(db, 'users')
      const seekersQuery = query(
        seekersRef,
        where('role', '==', 'jobseeker'),
        limit(pageSize),
        ...(isNextPage && lastVisible ? [startAfter(lastVisible)] : [])
      )

      const snapshot = await getDocs(seekersQuery)
      const seekersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))

      if (isNextPage) {
        setJobSeekers((prev) => [...prev, ...seekersData])
      } else {
        setJobSeekers(seekersData)
      }

      setFilteredSeekers(seekersData)
      setLastVisible(snapshot.docs[snapshot.docs.length - 1])
    } catch (error) {
      console.error('Error fetching job seekers:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchTotalJobSeekerCount = async () => {
    try {
      const seekersRef = collection(db, 'users')
      const seekersQuery = query(seekersRef, where('role', '==', 'jobseeker'))
      const snapshot = await getDocs(seekersQuery)
      setTotalSeekers(snapshot.size)
    } catch (error) {
      console.error('Error fetching total job seekers count:', error)
    }
  }

  const handleSelectSeeker = async (seeker) => {
    setLoading(true)
    try {
      const profileRef = doc(db, 'users', seeker.id, 'profiles', 'jobseeker')
      const profileSnap = await getDoc(profileRef)

      if (profileSnap.exists()) {
        setSelectedSeeker({
          ...seeker,
          profileData: profileSnap.data()
        })
      } else {
        setSelectedSeeker({
          ...seeker,
          profileData: null
        })
      }
    } catch (error) {
      console.error('Error fetching job seeker profile:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleNextPage = () => {
    fetchJobSeekers(true)
    setPage(page + 1)
  }

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1)
      fetchJobSeekers(false)
    }
  }

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'
    return new Date(timestamp.seconds * 1000).toLocaleDateString()
  }

  return (
    <Container>
      <SeekerList>
        <TotalCount>Total Members: {totalSeekers}</TotalCount>
        <SearchInput
          type="text"
          placeholder="Search by name or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {loading ? (
          <p>Loading members...</p>
        ) : (
          filteredSeekers
            .slice((page - 1) * pageSize, page * pageSize)
            .map((seeker) => (
              <SeekerCard
                key={seeker.id}
                selected={selectedSeeker?.id === seeker.id}
                onClick={() => handleSelectSeeker(seeker)}
              >
                <h4>
                  {seeker.first_name} {seeker.last_name}
                </h4>
                <p>Email: {seeker.email}</p>
              </SeekerCard>
            ))
        )}
        <PaginationControls>
          <PaginationButton onClick={handlePrevPage} disabled={page === 1}>
            Previous
          </PaginationButton>
          <PaginationButton
            onClick={handleNextPage}
            disabled={filteredSeekers.length < pageSize}
          >
            Next
          </PaginationButton>
        </PaginationControls>
      </SeekerList>

      <DetailView>
        {selectedSeeker ? (
          <>
            <SectionTitle>
              {selectedSeeker.first_name} {selectedSeeker.last_name}
            </SectionTitle>

            <DetailSection>
              <h4>Contact Info</h4>
              <p>Email: {selectedSeeker.email}</p>
              <p>Phone: {selectedSeeker.profileData?.phone || 'N/A'}</p>
              {selectedSeeker.profileData?.linkedin && (
                <p>LinkedIn: {selectedSeeker.profileData.linkedin}</p>
              )}
            </DetailSection>

            <DetailSection>
              <h4>Profile Details</h4>
              <p>Joined: {formatDate(selectedSeeker.created_at)}</p>
              <p>
                Location: {selectedSeeker.profileData?.city || 'N/A'},{' '}
                {selectedSeeker.profileData?.state || 'N/A'}
              </p>
            </DetailSection>

            <DetailSection>
              <h4>Experience</h4>
              <p>
                Years of Experience:{' '}
                {selectedSeeker.profileData?.years_experience || 'N/A'}
              </p>
              <p>
                Current Level:{' '}
                {selectedSeeker.profileData?.current_level || 'N/A'}
              </p>
              <p>
                Startup Experience:{' '}
                {selectedSeeker.profileData?.startup_experience ? 'Yes' : 'No'}
              </p>
            </DetailSection>

            {selectedSeeker.profileData?.work_history?.length > 0 && (
              <DetailSection>
                <h4>Work History</h4>
                {selectedSeeker.profileData.work_history.map((job, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <p>
                      <strong>{job.title}</strong> at {job.employer}
                    </p>
                    <p>
                      {job.start_date} -{' '}
                      {job.present ? 'Present' : job.end_date}
                    </p>
                  </div>
                ))}
              </DetailSection>
            )}

            <DetailSection>
              <h4>Skills</h4>
              {selectedSeeker.profileData?.technical_skills?.length ? (
                <div>
                  {selectedSeeker.profileData.technical_skills.map(
                    (skill, index) => (
                      <SkillTag key={index}>{skill}</SkillTag>
                    )
                  )}
                </div>
              ) : (
                <p>No technical skills listed</p>
              )}
            </DetailSection>

            {selectedSeeker.profileData?.industries?.length > 0 && (
              <DetailSection>
                <h4>Industries</h4>
                <div>
                  {selectedSeeker.profileData.industries.map(
                    (industry, index) => (
                      <SkillTag key={index}>{industry}</SkillTag>
                    )
                  )}
                </div>
              </DetailSection>
            )}
          </>
        ) : (
          <p>Select a member to view details</p>
        )}
      </DetailView>
    </Container>
  )
}

export default JobSeekersTab
