import React from 'react'
import styled from 'styled-components'
import { X } from 'lucide-react'
import { updateJobseekerProfile } from '../../models/JobSeekerProfile'

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
`

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  max-width: 28rem;
  width: 100%;
  margin: 0 1rem;
  position: relative;
`

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #6b7280;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0.5rem;

  &:hover {
    color: #374151;
  }
`

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const Subtitle = styled.p`
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
`

const FeaturesList = styled.ul`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
`

const FeatureIcon = styled.span`
  margin-right: 0.5rem;
`

const FeatureText = styled.span``

const ExploreButton = styled.button`
  width: 100%;
  background-color: #c859ff;
  color: white;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #b347e5;
  }
`

const ProWelcomeModal = ({ isOpen, onClose, userId }) => {
  if (!isOpen) return null

  const handleStartExploring = async () => {
    try {
      await updateJobseekerProfile(userId, {
        plan_welcome: true
      })
      onClose()
    } catch (error) {
      console.error('Error updating plan welcome status:', error)
    }
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <Title>Welcome to Arena Pro✨!</Title>
        <Subtitle>Your all-access pass includes:</Subtitle>

        <FeaturesList>
          <FeatureItem>
            <FeatureIcon>🎯</FeatureIcon>
            <FeatureText>
              Smart Job Matching with AI-powered recommendations
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>📚</FeatureIcon>
            <FeatureText>
              Resource Hub with industry events, career growth resources, and templates for success
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>📅</FeatureIcon>
            <FeatureText>
              Exclusive Arena Pro monthly office hours and networking events
            </FeatureText>
          </FeatureItem>
        </FeaturesList>

        <ExploreButton onClick={handleStartExploring}>
          Start exploring your exclusive VIP features now →
        </ExploreButton>
      </ModalContainer>
    </ModalOverlay>
  )
}

export default ProWelcomeModal
