import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import {
  ArrowLeft,
  Building,
  MapPin,
  BanknoteIcon,
  Clock,
  Briefcase,
  Globe,
  GraduationCap,
  Hash,
  Laptop,
  Calendar,
  Heart,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  Shield,
  HelpingHandIcon,
  Clapperboard,
  PartyPopper,
  Gift,
  Shirt,
  Ticket,
  Plane,
} from 'lucide-react'
import { getJobPosting } from '../../models/JobPosting'
import { getEmployerProfile } from '../../models/EmployerProfile'

import { auth } from '../../firebase'
import DOMPurify from 'dompurify'

const benefitCategories = [
  { value: 'Bonus/Stipends', label: 'Bonus/Stipends', icon: BanknoteIcon },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Discounts', label: 'Discounts', icon: BanknoteIcon},
  { value: 'Entertainment', label: 'Entertainment', icon: Clapperboard},
  {
    value: 'Employee Resource Groups',
    label: 'Employee Resource Groups',
    icon: HelpingHandIcon
  },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Equity', label: 'Equity', icon: BanknoteIcon },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Holidays', label: 'Holidays', icon: PartyPopper },
  { value: 'Insurance', label: 'Insurance', icon: Shield },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Miscellaneous ', label: 'Miscellaneous ', icon: Gift },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Swag', label: 'Swag', icon: Shirt },
  { value: 'Tickets', label: 'Tickets', icon: Ticket },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  { value: 'Travel', label: 'Travel', icon: Plane},
  { value: 'Wellness', label: 'Wellness', icon: Smile },
]
// Styled Components
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const ContentWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 1rem;

  &:hover {
    background: #e2e8f0;
    color: #1e293b;
  }
`

const JobHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const CompanyLogo = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: contain;
  background-color: #f8f9fa;
`

const JobTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e293b;
  margin-bottom: 6px;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`

const CompanyName = styled.span`
  font-weight: 600;
  color: #4a5568;
  font-size: 1rem;
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.primary
      ? `
    background-color: #805ad5;
    color: white;
    border: none;
    &:hover {
      background-color: #6b46c1;
    }
  `
      : `
    background-color: white;
    color: #805ad5;
    border: 1px solid #805ad5;
    &:hover {
      background-color: #f8f4fb;
    }
  `}
`

const Section = styled.section`
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1e293b;
  margin-bottom: 1rem;
`

const AboutRole = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); // Increased from 200px
  gap: 2rem; // Increased from 1.5rem
  margin-top: 2rem; // Added top margin
`

const AboutRoleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem; // Increased from 0.5rem
  padding: 0.5rem 0; // Added vertical padding
`

const AboutRoleLabel = styled.span`
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 0.25rem; // Added bottom margin
`

const AboutRoleValue = styled.span`
  font-weight: 600;
  color: #1a202c;
  display: flex;
  align-items: center;
  gap: 0.75rem; // Increased from 0.5rem

  > div {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
`
const RichTextContent = styled.div`
  color: #4a5568;
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4 {
    margin: 1.5rem 0 1rem;
    color: #1e293b;
    font-weight: 600;
  }

  ul,
  ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #805ad5;
    text-decoration: underline;
    &:hover {
      color: #6b46c1;
    }
  }
`
const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`

const SkillTag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f8f4fb;
  color: #805ad5;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
`
const List = styled.ul`
  list-style: none;
  padding: 0;
  li::marker {
    display: none;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #4a5568;
  list-style: none;
  &::before {
    content: '';
    display: inline-block;
    min-width: 0.5rem;
    height: 0.5rem;
    margin-top: 0.5rem;
    background-color: #caaae1;
    border-radius: 50%;
  }
`
const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
`

const BenefitCard = styled.div`
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 0.75rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const BenefitIcon = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: #f3e8ff;
  color: #9333ea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
`

const BenefitTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1a202c;
`

const BenefitDescription = styled.p`
  color: #718096;
  font-size: 0.875rem;
`
const PublicJobView = () => {
  const { jobId } = useParams()
  const navigate = useNavigate()
  const [employerProfile, setEmployerProfile] = useState(null)

  const [jobData, setJobData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const data = await getJobPosting(jobId)
        setJobData(data)
      } catch (error) {
        console.error('Error fetching job:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchJobDetails()
  }, [jobId])

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true)
        const job = await getJobPosting(jobId)
        setJobData(job)

        // Fetch Employer Profile
        if (job.employer_id) {
          const profile = await getEmployerProfile(job.employer_id)
          setEmployerProfile(profile)
        }
      } catch (error) {
        console.error('Error fetching job details:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchJobDetails()
  }, [jobId])

  const formatAdditionalCompensation = (additionalCompensation) => {
    if (!additionalCompensation || additionalCompensation.length === 0)
      return null
    return additionalCompensation.join(', ')
  }

  const handleApply = () => {
    if (auth.currentUser) {
      navigate(`/job-opening/${jobId}`)
    } else {
      window.location.href = 'https://app.arenatalent.com'
    }
  }

  if (loading) return <div>Loading...</div>
  if (!jobData) return <div>Job not found</div>

  const formatSalary = (salary) => {
    if (!salary) return 'Not specified'

    // Handle hidden salary type
    if (salary.type === 'hidden') return 'Undisclosed'

    // Handle unpaid salary type
    if (salary.type === 'unpaid') return 'Unpaid'

    // Format salary range
    const formatNumber = (num) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(num)

    const range = `${formatNumber(salary.range_start)} - ${formatNumber(
      salary.range_end
    )}`
    return salary.type === 'hourly' ? `${range}/hr` : `${range}/year`
  }

  const companyLogo =
    employerProfile?.company_logo_url || '/placeholder/company_logo.png'

  return (
    <PageWrapper>
      <ContentWrapper>
        <BackButton onClick={() => navigate(-1)}>
          <ArrowLeft size={18} />
          Back
        </BackButton>
        <JobHeader>
          <CompanyInfo>
            <CompanyLogo src={companyLogo} alt="Company Logo" />
            <div>
              <JobTitle>{jobData.title}</JobTitle>
              <CompanyName>{jobData?.employer_profile?.company_name || ''}</CompanyName>
            </div>
          </CompanyInfo>
          <Button primary onClick={handleApply}>
            {auth.currentUser ? 'Apply Now' : 'Sign in to Apply'}
          </Button>
        </JobHeader>
        <Section>
          <SectionTitle>About this role</SectionTitle>
          <AboutRole>
            {jobData.department && (
              <AboutRoleItem>
                <GraduationCap size={18} />
                <AboutRoleLabel>Department</AboutRoleLabel>
                <AboutRoleValue>{jobData.department}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.level && (
              <AboutRoleItem>
                <Briefcase size={18} />
                <AboutRoleLabel>Job Level</AboutRoleLabel>
                <AboutRoleValue>{jobData.level}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.type && jobData.type.length > 0 && (
              <AboutRoleItem>
                <Clock size={18} />
                <AboutRoleLabel>Job Type</AboutRoleLabel>
                <AboutRoleValue>{jobData.type.join(', ')}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.work_policy && (
              <AboutRoleItem>
                <Globe size={18} />
                <AboutRoleLabel>Work Policy</AboutRoleLabel>
                <AboutRoleValue>{jobData.work_policy}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.salary &&
              !isNaN(jobData.salary.range_start) &&
              !isNaN(jobData.salary.range_end) && (
                <AboutRoleItem>
                  <AboutRoleLabel>Compensation</AboutRoleLabel>
                  <AboutRoleValue
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}
                    >
                      <BanknoteIcon size={18} />
                      {formatSalary(jobData.salary)}
                    </div>
                    {jobData.additional_compensation?.length > 0 && (
                      <div
                        style={{
                          fontSize: '0.875rem',
                          color: '#6B7280',
                          marginTop: '0.25rem',
                          paddingLeft: '1.75rem'
                        }}
                      >
                        +{' '}
                        {formatAdditionalCompensation(
                          jobData.additional_compensation
                        )}
                      </div>
                    )}
                  </AboutRoleValue>
                </AboutRoleItem>
              )}
            {jobData.location && (
              <AboutRoleItem>
                <MapPin size={18} />
                <AboutRoleLabel>Location</AboutRoleLabel>
                <AboutRoleValue>{jobData.location}</AboutRoleValue>
              </AboutRoleItem>
            )}
          </AboutRole>
        </Section>
        <Section>
          <SectionTitle>Job Summary</SectionTitle>
          <RichTextContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(jobData.description)
            }}
          />
        </Section>

        {jobData.responsibilities && (
          <Section>
            <SectionTitle>Responsibilities</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(jobData.responsibilities)
              }}
            />
          </Section>
        )}
        {jobData.requirements && (
          <Section>
            <SectionTitle>Requirements</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(jobData.requirements)
              }}
            />
          </Section>
        )}
        {jobData.nice_to_haves && (
          <Section>
            <SectionTitle>Preferred Qualifications</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(jobData.nice_to_haves)
              }}
            />
          </Section>
        )}
         {jobData.recommended_skills &&
          jobData.recommended_skills.length > 0 && (
            <Section>
              <SectionTitle>Required Skills</SectionTitle>
              <List>
                {jobData.recommended_skills.map((skill, index) => (
                  <ListItem key={index}>{skill}</ListItem>
                ))}
              </List>
            </Section>
          )}

        {jobData.technical_skills?.length > 0 && (
          <Section>
            <SectionTitle>Technical Skills</SectionTitle>
            <SkillsContainer>
              {jobData.technical_skills.map((skill, index) => (
                <SkillTag key={index}>
                  <Hash size={16} />
                  {skill}
                </SkillTag>
              ))}
            </SkillsContainer>
          </Section>
        )}

        {jobData.benefits && jobData.benefits.length > 0 && (
          <Section>
            <SectionTitle>Benefits</SectionTitle>
            <BenefitsGrid>
              {jobData.benefits.map((benefit, index) => {
                const categoryInfo = benefitCategories.find(
                  (cat) => cat.value === benefit.category.toLowerCase()
                ) || { icon: Briefcase, label: benefit.category }

                const Icon = categoryInfo.icon

                return (
                  <BenefitCard key={index}>
                    <BenefitIcon>
                      <Icon size={24} />
                    </BenefitIcon>
                    <BenefitTitle>{categoryInfo.label}</BenefitTitle>
                    <BenefitDescription>
                      {benefit.description}
                    </BenefitDescription>
                  </BenefitCard>
                )
              })}
            </BenefitsGrid>
          </Section>
        )}

      </ContentWrapper>
    </PageWrapper>
  )
}

export default PublicJobView
