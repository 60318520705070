import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Mail } from 'lucide-react'

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
`

const NotificationPanelWrapper = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 0;
  width: 24rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transform: ${(props) =>
    props.isOpen ? 'translateY(0)' : 'translateY(100%)'};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

const PanelHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PanelTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
`

const NotificationsContent = styled.div`
  max-height: 24rem;
  overflow-y: auto;
`

const EmptyNotification = styled.div`
  padding: 2rem;
  text-align: center;
  color: #6b7280;
`

const NotificationItem = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #f3f4f6;

  &:hover {
    background-color: #f9fafb;
  }
`

const NotificationTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
`

const NotificationText = styled.p`
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 1rem;
  line-height: 1.4;
`

const NotificationButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  border: 1px solid #c859ff;

  &:hover {
    background-color: white;
    color: #c859ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`

const ToggleButton = styled.button`
  position: relative;
  padding: 0.75rem;
  cursor: pointer;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`

const NotificationBadge = styled.span`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  background-color: #ef4444;
  color: white;
  font-size: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
`

const ClearAllButton = styled.button`
  font-size: 0.875rem;
  color: #6b7280;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: #f3f4f6;
  }
`

const NotificationPanel = ({ isPaidUser, isPresaleUser }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    // Check if notification has been cleared before
    const isOfficeHoursCleared = localStorage.getItem('officeHoursCleared')

    // Only show notification for paid users and if not cleared before
    if (isPaidUser && !isOfficeHoursCleared) {
      setNotifications([
        {
          id: 'office-hours-feb12',
          title: 'Register to our upcoming Arena Pro✨ Office Hours Q&A (4/2)',
          message:
            ' and get live personalized career advice on your job search, career growth & beyond.',
          ctaText: 'Register Here',
          link: 'https://lu.ma/81ces23d'
        }
      ])
    }
  }, [isPaidUser])

  useEffect(() => {
    // Check if notification has been cleared before
    const presaleMadePlanSelection = localStorage.getItem('presaleMadePlanSelection')

    // Only show notification if user is a presale member and hasn't made a selection yet
    if (isPresaleUser && !presaleMadePlanSelection) {
      setNotifications(prev => { 
        // Prevent duplicate notifications
        if (prev.some(n => n.id === 'presale-notif')) return prev 
  
        return [
          ...prev,
          {
          id: 'presale-notif',
          title: '⚠️ Important: Your Arena Pro Access Expires on April 6',
          message: (
            <div>
              Choose your path to continue:

              <NotificationButton
                style={{
                  marginTop: '6px', 
                  marginBottom: '6px'
                }}
                onClick={() => window.open("https://buy.stripe.com/28o03yc5z1DcdpK4gu", '_blank')
                }
              >
                <strong>Keep Arena Pro</strong> ($14.99/mo or $34.99/quarter)
              </NotificationButton>
              <div> ✔ AI-powered job matches for every role</div>
              <div> ✔ Monthly Office Hours with industry leaders</div>
              <div> ✔ 1000+ career resources in Resource Hub</div>
              <div> ✔ Industry events calendar</div>
              <div> ✔ Professional templates (resume, cover letter, networking)</div>
              <div> ✔ Premium industry insights & education</div>
            
              <NotificationButton
                style={{
                  marginTop: '6px', 
                  marginBottom: '6px'
                }}
                onClick={handleCancelSubscription}
              >
                <strong>Switch to Free</strong>
              </NotificationButton>
              <div> ✔ Basic job search & apply</div>
              <div> ✔ Application tracking</div>
              <div> ✔ Recruiter messaging</div>
              <div> ✔ Job alerts</div>
              <div> ✔ Basic salary insights</div>
              <br/>
            
              <strong>Select an option to continue using Arena.</strong>
            </div> 
            ),
        }
      ]})
    }
    
  }, [isPresaleUser])

  const handleCancelSubscription = async () => {
    localStorage.setItem('presaleMadePlanSelection', 'true')
    setNotifications(prev => prev.filter(n => n.id !== 'presale-notif'))
    // Add logic to defer cancellation until Apr 6

  }


  const togglePanel = () => {
    setIsOpen(!isOpen)
  }

  const handleClearAll = () => {
    // Store in localStorage that notification has been cleared
    localStorage.setItem('officeHoursCleared', 'true')
    // Prevent presale notification from being cleared without selection
    setNotifications(prev => prev.filter(n => n.id === 'presale-notif'))
  }

  const handleNotificationClick = (link) => {
    window.open(link, '_blank')
  }

  return (
    <NotificationContainer>
      <NotificationPanelWrapper isOpen={isOpen}>
        <PanelHeader>
          <PanelTitle>Notifications</PanelTitle>
          {notifications.length > 0 && (
            <ClearAllButton onClick={handleClearAll}>Clear all</ClearAllButton>
          )}
        </PanelHeader>
        <NotificationsContent>
          {notifications.length === 0 ? (
            <EmptyNotification>No notifications</EmptyNotification>
          ) : (
            notifications.map((notification) => (
              <NotificationItem key={notification.id}>
                <NotificationTitle>{notification.title}</NotificationTitle>
                <NotificationText>{notification.message}</NotificationText>
                
                {notification.ctaText && 
                  <NotificationButton
                    onClick={() => handleNotificationClick(notification.link)}
                  >
                    {notification.ctaText}
                  </NotificationButton>
                }
              </NotificationItem>
            ))
          )}
        </NotificationsContent>
      </NotificationPanelWrapper>

      <ToggleButton onClick={togglePanel}>
        <Mail size={24} color="#4b5563" />
        {notifications.length > 0 && (
          <NotificationBadge>{notifications.length}</NotificationBadge>
        )}
      </ToggleButton>
    </NotificationContainer>
  )
}

export default NotificationPanel
