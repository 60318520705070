import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { useLoadScript, Autocomplete } from '@react-google-maps/api'
import { Eye, EyeOff, Info } from 'lucide-react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { getTrialDetails } from '../models/Coupons'
import { sendPasswordResetEmail } from 'firebase/auth'
import isAtLeast18 from '../utils/isAtLeast18'
import { createOrUpdateJobseekerProfile } from '../models/JobSeekerProfile'
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { auth, db } from '../firebase'
import { signupMailchimpFlow } from '../functions/signupMailchimpFlow'

const libraries = ['places']

// Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`

// Styled Components
const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
  padding: 20px;
`

// const ContentBox = styled.div`
//   background-color: rgba(255, 255, 255, 0.95);
//   border-radius: 24px;
//   box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
//   padding: 40px;
//   max-width: 400px;
//   width: 100%;
//   animation: ${fadeIn} 0.5s ease-out;
//   text-align: center;
// `

const Logo = styled.img`
  width: ${(props) => (props.large ? '128px' : '96px')};
  height: ${(props) => (props.large ? '128px' : '96px')};
  margin: 0 auto 24px;
  animation: ${slideUp} 0.5s ease-out;
`

const Button = styled.button`
  background-color: ${(props) => (props.secondary ? 'white' : '#8b5cf6')};
  color: ${(props) => (props.secondary ? '#4f46e5' : 'white')};
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 9999px;
  border: none;
  width: 100%;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  animation: ${slideUp} 0.5s ease-out 0.3s both;

  &:hover {
    background-color: ${(props) => (props.secondary ? '#f3f4f6' : '#7c3aed')};
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.25);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

const Label = styled.label`
  display: block;
  font-weight: 500;
  color: white;
  margin-bottom: 0.5rem;
`

const Input = styled.input`
  width: 97%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 9999px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8b5cf6;
    box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.1);
  }
`

const PasswordWrapper = styled.div`
  position: relative;
`

const TogglePassword = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #a0aec0;
`

const ErrorMessage = styled.div`
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
`

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
`

const Tooltip = styled.div`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1f2937;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 10;
`
const TermsText = styled.p`
  font-size: 0.75rem;
  color: white;
  margin-top: 1rem;
  text-align: center;
  line-height: 1.5;

  a {
    color: #caaae1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Title = styled.h1`
  margin-top: -20px;
  font-size: 1rem;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 0.5rem;
  animation: ${slideUp} 0.5s ease-out 0.1s both;
`

const SubTitle = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: 1.5rem;
  animation: ${slideUp} 0.5s ease-out 0.2s both;
  line-height: 1.2;
  background: linear-gradient(to bottom, #ffffff 0%, #b8c7ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`

const TagLine = styled.p`
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 2.5rem;
  animation: ${slideUp} 0.5s ease-out 0.3s both;
  opacity: 0.9;
`

const ContentBox = styled.div`
  background-color: rgba(
    33,
    33,
    33,
    0.9
  ); // Darker, semi-transparent background
  border-radius: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 40px;
  max-width: 700px;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-out;
  text-align: center;
`
const MSBCLink = styled.p`
  font-size: 1rem;
  color: #ffffff;
  margin-top: 1rem;
  opacity: 0.9;
  animation: ${slideUp} 0.5s ease-out 0.4s both;

  a {
    color: #b8c7ff;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }
  }
`

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 8px;
`

const ToggleButton = styled.button`
  background: ${(props) => (props.active ? '#8b5cf6' : 'transparent')};
  color: ${(props) => (props.active ? 'white' : '#8b5cf6')};
  font-weight: bold;
  border: none;
  padding: 0.75rem 1.5rem; // Increased padding for larger size
  font-size: 1.1rem; // Slightly larger font
  border-radius: 9999px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};

  &:hover {
    background: ${(props) =>
      props.disabled
        ? 'transparent'
        : props.active
        ? '#7c3aed'
        : 'rgba(139, 92, 246, 0.1)'};
  }
`

const StepDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#8b5cf6' : '#e2e8f0')};
  transition: all 0.3s ease;
`

const FormGroup = styled.div`
  margin-bottom: 1rem;
  text-align: left;

  label::after {
    content: ${(props) => (props.optional ? '' : ' *')};
    color: #ef4444;
  }
`

const ResetPasswordLink = styled.button`
  background: none;
  border: none;
  color: #b8c7ff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  transition: color 0.2s;

  &:hover {
    color: white;
  }
`

const SuccessMessage = styled.div`
  color: #10b981;
  background: rgba(16, 185, 129, 0.1);
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 0.875rem;
`

const ErrorMessageWithLink = styled(ErrorMessage)`
  a {
    color: #b8c7ff;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }
`
const InfoTooltip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 8px;

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`

const TooltipText = styled.div`
  visibility: hidden;
  opacity: 0;
  background-color: #1f2937;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  z-index: 10;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  white-space: nowrap;
  transition: opacity 0.2s;
`
const HomePage = () => {
  // View state
  const [view, setView] = useState('welcome')
  const [isEmployer, setIsEmployer] = useState(false)

  // Form states
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [website, setWebsite] = useState('')

  // UI states
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [step, setStep] = useState(1)
  const [couponCode, setCouponCode] = useState('')
  const navigate = useNavigate()
  const autocompleteRef = React.useRef(null)
  const [placeholder, setPlaceholder] = useState('')

  const [showResetPassword, setShowResetPassword] = useState(false)
  const [resetSuccess, setResetSuccess] = useState('')
  const [showBirthDateTooltip, setShowBirthDateTooltip] = useState(false)
  const [birthDate, setBirthDate] = useState('')

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCrPAUAvwtCGCro8f8GXJw7neQ6a3T4Ox0',
    libraries: ['places']
  })

  const validatePassword = () => {
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long')
      return false
    } else if (!/\d/.test(password)) {
      setPasswordError('Password must contain at least one number')
      return false
    } else if (password !== confirmPassword) {
      setPasswordError('Passwords do not match')
      return false
    }
    setPasswordError('')
    return true
  }

  const handleResetPassword = async (e) => {
    e.preventDefault()
    if (!email) {
      setError('Please enter your email address')
      return
    }

    try {
      await sendPasswordResetEmail(auth, email)
      setResetSuccess('Password reset email sent! Please check your inbox.')
      setError('')
    } catch (err) {
      setError(err.message)
    }
  }

  const validateEmployerFields = () => {
    if (!companyName || !companyAddress || !companyPhone || !website) {
      setError('All employer fields are required.')
      return false
    }
    setError('')
    return true
  }

  const handleSignUp = async (e) => {
    e.preventDefault()

    // Basic validation
    if (!validatePassword()) return
    if (isEmployer && !validateEmployerFields()) return

    // Age validation for jobseekers
    if (!isEmployer) {
      if (!birthDate) {
        setError('Please enter your date of birth')
        return
      }

      if (!isAtLeast18(birthDate)) {
        setError(
          'Sorry, you must be at least 18 years old to use Arena. Think this is a mistake? Please email support@arenatalent.com'
        )
        return
      }
    }

    try {
      let trialDetails
      if (isEmployer) {
        try {
          trialDetails = await getTrialDetails(couponCode || null)
        } catch (couponError) {
          if (couponError.code === 'coupon/not-found') {
            setError(
              <ErrorMessageWithLink>
                Coupon not found. Please email{' '}
                <a href="mailto:support@arenatalent.com">
                  support@arenatalent.com
                </a>
              </ErrorMessageWithLink>
            )
          } else if (couponError.code === 'coupon/expired') {
            setError(
              <ErrorMessageWithLink>
                Coupon is expired. Please email{' '}
                <a href="mailto:support@arenatalent.com">
                  support@arenatalent.com
                </a>
              </ErrorMessageWithLink>
            )
          } else {
            setError('Error validating coupon. Please try again.')
          }
          return
        }
      }

      // Create Firebase auth user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user

      // Base user data
      const userData = {
        email,
        role: isEmployer ? 'employer' : 'jobseeker',
        first_name: firstName,
        last_name: lastName,
        created_at: serverTimestamp(),
        last_login: serverTimestamp(),
        company_name: isEmployer ? companyName : null
      }

      // Add employer-specific data if employer
      if (isEmployer) {
        userData.employer_id = user.uid
        userData.company_name = companyName
        userData.company_address = companyAddress
        userData.company_phone = companyPhone
        userData.website = website
        userData.subscription = {
          trial_end: trialDetails.trialEnd,
          job_post_limit: trialDetails.jobPostLimit,
          plan_type: trialDetails.planType,
          coupon_used: trialDetails.couponUsed,
          status: 'active',
          created_at: serverTimestamp()
        }
      } else {
        // Create jobseeker profile
        try {
          await createOrUpdateJobseekerProfile(user.uid, {
            date_of_birth: birthDate,
            intake_completed: false,
            demographics_completed: false,
            favorites: [],
            jobs_applied: [],
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            plan_type: 'freetrial'
          })
        } catch (profileError) {
          console.error('Error creating jobseeker profile:', profileError)
          // Continue with signup but log the error
        }
      }

      // Save user data
      await setDoc(doc(db, 'users', user.uid), userData)

      // Handle employer coupon usage
      if (isEmployer && trialDetails?.couponUsed) {
        await setDoc(
          doc(db, 'coupon_usage', `${trialDetails.couponUsed}_${user.uid}`),
          {
            coupon_code: trialDetails.couponUsed,
            user_id: user.uid,
            used_at: serverTimestamp(),
            trial_end: trialDetails.trialEnd
          }
        )
      }

      // Add Mailchimp signup flow
      try {
        await signupMailchimpFlow(userData)
      } catch (mailchimpError) {
        console.error('Error adding user to Mailchimp:', mailchimpError)
        if (mailchimpError.code === 'functions/internal') {
          setError(
            'There was an internal server error. Your account has been created, but you may not receive our welcome email. Please contact support if this persists.'
          )
        } else {
          setError(
            'Your account was created, but there was an issue adding you to our mailing list. You may not receive our welcome email.'
          )
        }
        if (mailchimpError.details) {
          console.error('Additional error details:', mailchimpError.details)
        }
      }

      // Determine redirect path
      const dashboardPath = isEmployer
        ? '/employer-dashboard'
        : '/member-dashboard'

      // Navigate in React Router
      navigate(dashboardPath, {
        state: {
          firstName,
          lastName,
          companyName: isEmployer ? companyName : undefined
        }
      })
    } catch (error) {
      // Handle various Firebase auth errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError(
            <ErrorMessageWithLink>
              Email already exists. Please{' '}
              <a onClick={() => setView('login')}>login here</a>.
            </ErrorMessageWithLink>
          )
          break
        case 'auth/invalid-email':
          setError('Please enter a valid email address.')
          break
        case 'auth/weak-password':
          setError('Password should be at least 6 characters.')
          break
        case 'auth/network-request-failed':
          setError('Network error. Please check your internet connection.')
          break
        case 'auth/too-many-requests':
          setError('Too many attempts. Please try again later.')
          break
        default:
          setError('An error occurred during signup. Please try again.')
          console.error('Signup error:', error)
      }
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setError('')

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user
      const userDoc = await getDoc(doc(db, 'users', user.uid))

      if (userDoc.exists()) {
        const userData = userDoc.data()
        const dashboardPath =
          userData.role === 'employer'
            ? '/employer-dashboard'
            : '/member-dashboard'
        navigate(dashboardPath, {
          state: {
            firstName: userData.first_name,
            lastName: userData.last_name,
            companyName: userData.company_name
          }
        })
      } else {
        setError('User data not found. Please contact support.')
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          setError(
            <ErrorMessageWithLink>
              Incorrect password.{' '}
              <a onClick={() => setShowResetPassword(true)}>
                Reset password here
              </a>
              .
            </ErrorMessageWithLink>
          )
          break
        case 'auth/user-not-found':
          setError('No account found with this email address.')
          break
        case 'auth/invalid-email':
          setError('Please enter a valid email address.')
          break
        case 'auth/too-many-requests':
          setError('Too many failed attempts. Please try again later.')
          break
        default:
          setError('An error occurred during login. Please try again.')
          console.error('Login error:', error)
      }
    }
  }

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace()
      if (place && place.formatted_address) {
        setCompanyAddress(place.formatted_address)
      }
    }
  }

  if (view === 'welcome') {
    return (
      <PageContainer>
        <ContentBox>
          <Logo src="/images/logo-white.png" alt="Arena Logo" large />
          <Title>Your AI-powered career platform for</Title>
          <SubTitle>
            Sports, Media
            <br />& Entertainment
          </SubTitle>
          <TagLine>
            Fast-track your job search or hiring efforts on Arena
          </TagLine>
          <Button onClick={() => setView('signup')}>Sign Up</Button>
          <Button
            onClick={() => setView('login')}
            secondary
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #FFFFFF',
              color: '#FFFFFF'
            }}
          >
            Login
          </Button>
          {/* <MSBCLink>
            MSBC partner or attendee?{' '}
            <a
              href="https://msbc.arenatalent.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login here
            </a>
          </MSBCLink> */}
        </ContentBox>
      </PageContainer>
    )
  }

  if (view === 'signup') {
    return (
      <PageContainer>
        <ContentBox>
          <Logo src="/images/logo-white.png" alt="Arena Logo" />

          <ToggleContainer>
            <ToggleButton
              active={!isEmployer}
              onClick={() => setIsEmployer(false)}
            >
              Member
            </ToggleButton>
            <ToggleButton
              active={isEmployer}
              onClick={() => setIsEmployer(true)}
            >
              Employer
            </ToggleButton>
          </ToggleContainer>

          <StepIndicator>
            <StepDot active={step === 1} />
            <StepDot active={step === 2} />
          </StepIndicator>

          <form onSubmit={handleSignUp}>
            {step === 1 ? (
              <>
                {isEmployer && (
                  <FormGroup>
                    <Label>Company Name</Label>
                    <Input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required={isEmployer}
                    />
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </FormGroup>
                {!isEmployer && (
                  <FormGroup>
                    <Label>Date of Birth</Label>
                    <div
                      onMouseEnter={() => setShowBirthDateTooltip(true)}
                      onMouseLeave={() => setShowBirthDateTooltip(false)}
                      style={{ position: 'relative' }}
                    >
                      <Input
                        type="date"
                        value={birthDate}
                        style={{
                          fontFamily: 'Inter',
                          '&::-webkit-datetime-edit': birthDate
                            ? 'initial'
                            : 'transparent'
                        }}
                        onChange={(e) => setBirthDate(e.target.value)}
                        required
                        max={new Date().toISOString().split('T')[0]}
                      />
                      <Tooltip visible={showBirthDateTooltip}>
                        Must be 18 or older to use Arena
                      </Tooltip>
                    </div>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Password</Label>
                  <PasswordWrapper>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={validatePassword}
                      required
                    />
                    <TogglePassword
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                    </TogglePassword>
                  </PasswordWrapper>
                </FormGroup>

                <FormGroup>
                  <Label>Confirm Password</Label>
                  <PasswordWrapper>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={validatePassword}
                      required
                    />
                  </PasswordWrapper>
                  {passwordError && (
                    <ErrorMessage>{passwordError}</ErrorMessage>
                  )}
                </FormGroup>

                <Button
                  type="button"
                  onClick={() => {
                    if (
                      validatePassword() &&
                      firstName &&
                      lastName &&
                      email &&
                      (!isEmployer || companyName)
                    ) {
                      setStep(isEmployer ? 2 : 1)
                      if (!isEmployer) {
                        handleSignUp({ preventDefault: () => {} })
                      }
                    } else {
                      setError('Please fill in all required fields')
                    }
                  }}
                >
                  {isEmployer ? 'Next' : 'Sign Up'}
                </Button>
                <Button
                  onClick={() => setView('login')}
                  secondary
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid #FFFFFF',
                    color: '#FFFFFF'
                  }}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <FormGroup>
                  <Label>
                    Your Phone Number
                    <InfoTooltip>
                      <Info size={16} color="#8b5cf6" />
                      <TooltipText className="tooltip-text">
                        This is not included on your public profile.
                      </TooltipText>
                    </InfoTooltip>
                  </Label>
                  <Input
                    type="tel"
                    value={companyPhone}
                    onChange={(e) => setCompanyPhone(e.target.value)}
                    required
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    title="Phone format: 123-456-7890"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    Company Address
                    <InfoTooltip>
                      <Info size={16} color="#8b5cf6" />
                      <TooltipText className="tooltip-text">
                        This is not included on your public profile.
                      </TooltipText>
                    </InfoTooltip>
                  </Label>
                  {isLoaded && (
                    <Autocomplete
                      onLoad={(autocomplete) => {
                        autocompleteRef.current = autocomplete
                      }}
                      onPlaceChanged={handlePlaceChanged}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                      }}
                    >
                      <Input
                        type="text"
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        required
                      />
                    </Autocomplete>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label>Company Website</Label>
                  <Input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    required
                  />
                </FormGroup>

                {/* <FormGroup optional>
                  <Label>Coupon Code (optional)</Label>
                  <Input
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                </FormGroup> */}

                <Button type="submit">Sign Up</Button>
                <Button type="button" secondary onClick={() => setStep(1)}>
                  Back
                </Button>
              </>
            )}

            <TermsText>
              By signing up, you agree to Arena's{' '}
              <a
                href="https://www.arenatalent.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://www.arenatalent.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </TermsText>
          </form>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ContentBox>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <ContentBox>
        <Logo src="/images/logo-white.png" alt="Arena Logo" />
        {!showResetPassword ? (
          <form onSubmit={handleLogin}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
            </FormGroup>

            <FormGroup>
              <Label>Password</Label>
              <PasswordWrapper>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Enter your password"
                />
                <TogglePassword
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                </TogglePassword>
              </PasswordWrapper>
            </FormGroup>

            <Button type="submit">Login</Button>
            <Button secondary onClick={() => setView('welcome')}>
              Back
            </Button>

            <ResetPasswordLink
              type="button"
              onClick={() => {
                setShowResetPassword(true)
                setError('')
                setResetSuccess('')
              }}
            >
              Forgot your password?
            </ResetPasswordLink>

            <TermsText>
              By logging in, you agree to Arena's{' '}
              <a
                href="https://www.arenatalent.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://www.arenatalent.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </TermsText>
          </form>
        ) : (
          <form onSubmit={handleResetPassword}>
            <Title style={{ color: 'white', marginBottom: '1.5rem' }}>
              Reset Password
            </Title>

            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
            </FormGroup>

            <Button type="submit">Send Reset Link</Button>
            <Button
              type="button"
              secondary
              onClick={() => {
                setShowResetPassword(false)
                setError('')
                setResetSuccess('')
              }}
            >
              Back to Login
            </Button>

            {resetSuccess && <SuccessMessage>{resetSuccess}</SuccessMessage>}
          </form>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ContentBox>
    </PageContainer>
  )
}

export default HomePage
