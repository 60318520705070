import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth, db } from '../../firebase'
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import {
  ChevronDown,
  View,
  BanknoteIcon,
  Clock,
  MapPin,
  Briefcase,
  Laptop,
  Lock
} from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import React, { useEffect, useState, useMemo } from 'react'
import NotificationPanel from './NotificationPanel'

import { getEmployers } from '../../models/User'
import { Bar } from 'react-chartjs-2'
import ApplicationStages from './ApplicationStages'
import { getJobseekerApplications } from '../../models/JobApplication'
import { ToastContainer, toast } from 'react-toastify'
import ProWelcomeModal from './ProWelcomeModal'
import { onSnapshot, disableNetwork, enableNetwork } from 'firebase/firestore'
import Joyride from 'react-joyride'
import Match from '../Match'

import 'react-toastify/dist/ReactToastify.css'

import {
  getEmployerProfile,
  getEmployerMembers,
  getJobSeekers,
  getAllEmployerProfiles // Add this import
} from '../../models/EmployerProfile'
import 'chart.js/auto' // This line is necessary to auto-register the required chart.js components

// Styled components
const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  min-height: 100vh;

  background-color: #f3f4f6;
`
const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;
`

const Link = styled.a`
  color: white;
  background-color: ${softColors.icontext};
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.2s;
  border: 1px solid ${softColors.icontext};
  width: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: -10px;

  &:hover {
    background-color: white;
    color: ${softColors.icontext};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const BannerLink = styled.a`
  color: white;

  &:hover {
    font-weight: 700;
  }
`
const ProgressBarContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${softColors.border};
  border-radius: 9999px;
  height: 0.5rem;
  margin-bottom: 0.5rem;
`

const ProgressBar = styled.div`
  height: 0.5rem;
  border-radius: 9999px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color || softColors.primary};
`

const MainContent = styled.main`
  flex: 1;

  overflow-y: auto;
`

const ContentContainer = styled.div`
  padding: 1.5rem;
`

const WelcomeHeader = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: ${(props) =>
      `repeat(${props.columns}, minmax(0, 1fr))`};
  }
`

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
`

const CardHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
`

const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`

const CardContent = styled.div`
  padding: 1rem 1.5rem;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: #f9fafb;
  @media (max-width: 768px) {
    padding: 0.25rem;
    font-size: 0.7rem;
    letter-spacing: 0em;
  }
`

const TableCell = styled.td`
  padding: 1rem 1.5rem;
  white-space: nowrap;
  @media (max-width: 768px) {
    padding: 1rem 0.25rem;
    font-size: 0.7rem;
    white-space: wrap;
  }
`

const OnboardingSection = styled(Card)`
  margin-bottom: 1.5rem;
  height: auto;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
`

const SectionIcon = styled.span`
  margin-right: 0.5rem;
`

const SectionProgressBar = styled(ProgressBarContainer)`
  margin: 0.5rem 0;
  position: relative;
`

const SectionProgress = styled(ProgressBar)`
  background-color: ${(props) =>
    props.started ? softColors.success : softColors.danger};
`

const SectionContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  overflow: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  padding: ${(props) => (props.isOpen ? '1rem 0' : '0')};
  transition: max-height 0.1s ease-in-out, opacity 0.3s ease-in-out,
    padding 0.3s ease-in-out;
`

const SectionDescription = styled.p`
  font-size: 0.875rem;
  color: ${softColors.textLight};
  line-height: 1.5;
`

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: -0.5rem;
  color: ${softColors.text};
  max-width: 90%;
`
const Tooltip = styled.span`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%; /* Arrow pointing down */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`

// Wrapper for the button and tooltip
const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`

// Button styled component
const Button = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.7;
  border: none;
  font-size: 14px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #b347e5;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #c859ff;
  }
`

const ViewApplicationsButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #c859ff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;

  &:hover {
    background-color: white;
    color: #c859ff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const StatusCard = styled(Card)`
  padding: 1.5rem;
`

const StatusTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
`

const StageContainer = styled.div`
  margin-bottom: 1rem;
`

const StageLabel = styled.div`
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
`

const StageProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
`

const StageProgress = styled.div`
  height: 100%;
  border-radius: 4px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color};
`

const MatchesInfo = styled.div`
  color: #666;
  font-size: 0.875rem;
  margin-top: 1.5rem;
`

const ReviewButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 0.75rem;

  &:hover {
    background-color: #b347e5;
  }
`
const PulseAnimation = styled.div`
  animation: pulse 2s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: #f1f5f9;
    }
    50% {
      background-color: #f7fafc;
    }
    100% {
      background-color: #f1f5f9;
    }
  }
`
const SkeletonImage = styled(PulseAnimation)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;

  background-color: #f1f5f9;
`
const SkeletonText = styled(PulseAnimation)`
  display: inline-block;
  flex: 1;
  width: ${(props) => props.width || '80%'};
  height: 24px;
  border-radius: 8px;
  background-color: #f1f5f9;

  @media (max-width: 425px) {
    max-width: ${(props) => props.maxWidth || 'none'};
  }
`

const TableSubCell = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
const InfoBanner = styled.div`
  background-color: rgb(255, 0, 244);
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
`
const Emoji = styled.img`
  height: 1rem;
  object-fit: contain;
`
const UpgradeButton = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #d29856;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  z-index: 1001; // Added to ensure bubble appears above other content
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
  }
`
const CompanyInfo = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`
const JobTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #1a202c;
  margin: 0 0 4px 0;
  @media (min-width: 1024px) {
    max-width: calc(100% - 100px);
  }
`
const JobMetadata = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 14px;
  color: #4a5568;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

const MatchBubble = styled.a`
  background-color: #d29856;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  cursor: pointer;

  z-index: 1; // Added to ensure bubble appears above other content

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipText = styled.div`
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  white-space: normal;
  min-width: 200px;
  max-width: 300px;
  word-wrap: break-word;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }

  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  .button {
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    color: #1a202c;
    padding: 4px 8px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #edf2f7;
    }
  }
`
export default function JobSeekerDash() {
  const steps = useMemo(
    () => [
      {
        target: 'body',
        content: (
          <div>
            <strong>Welcome to Arena!</strong>
            <br /> Let's take a quick tour to help you navigate the platform.
          </div>
        ),
        placement: 'center'
      },
      {
        target: '#intake-section',
        content: (
          <div>
            Complete this quick profile setup to unlock all of Arena's features.
          </div>
        )
      },
      {
        target: '#jobs-applied-section',
        content: 'Track your applications at a glance.',
        placement: 'left'
      },
      {
        target: '#profile-button',
        content: 'View and update your profile here.',
        placement: 'right'
      },
      {
        target: '#job-search-button',
        content: 'Find jobs in sports, media & entertainment.',
        placement: 'right'
      },
      {
        target: '#company-search-button',
        content: 'Find companies hiring in sports, media & entertainment.',
        placement: 'right'
      },
      {
        target: '#application-tracking-button',
        content:
          'Never miss an update. Track your entire application journey in one convenient place.',
        placement: 'right'
      },
      {
        target: '#jobseeker-messaging-button',
        content: 'Connect directly with recruiters.',
        placement: 'right'
      },
      {
        target: '#ai-transparency-section',
        content:
          'Curious how we connect you with opportunities? Learn more below.',
        placement: 'top'
      }
    ],
    []
  )
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0) // Reset step index
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingEmployers, setLoadingEmployers] = useState(true)
  const [error, setError] = useState(null)
  const location = useLocation()
  const refreshParam = new URLSearchParams(location.search).get('refresh')

  const [applications, setApplications] = useState([])
  const [showProWelcomeModal, setShowProWelcomeModal] = useState(false)
  const [employers, setEmployers] = useState([])
  const [userName, setUserName] = useState('')
  const [openSections, setOpenSections] = useState({
    profile: false,
    postJob: false,
    survey: false
  })
  const [jobPostings, setJobPostings] = useState([])
  const [recentJobPostings, setRecentJobPostings] = useState([])
  const [loadingJobs, setLoadingJobs] = useState(true)

  const navigate = useNavigate()

  const barData = {
    labels: [
      'Submitted',
      'Reviewed',
      'Shortlisted',
      'Interview',
      'Offer',
      'Rejected',
      'Hired'
    ],
    datasets: [
      {
        label: 'Application Status',
        data: [], // Sample data
        backgroundColor: '#C859FF',
        borderColor: '#C859FF',
        borderWidth: 1
      }
    ]
  }

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true)
        const user = auth.currentUser
        console.log('Current user:', user)

        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          console.log('User doc exists:', userDoc.exists())

          if (userDoc.exists()) {
            const mainUserData = userDoc.data()
            console.log('Full mainUserData:', mainUserData)
            console.log('plan_selected value:', mainUserData.plan_selected)
            setUserName(mainUserData.first_name)

            const shouldRedirect =
              !mainUserData.plan_selected && mainUserData.plan_selected !== true
            console.log('Should redirect?', shouldRedirect)

            if (shouldRedirect) {
              console.log('Redirecting to plan selection...')
              navigate('/member-select-plan')
              setLoading(false)
              return
            }

            const profileRef = doc(
              db,
              'users',
              user.uid,
              'profiles',
              'jobseeker'
            )
            const profileSnap = await getDoc(profileRef)

            if (profileSnap.exists()) {
              const profileData = profileSnap.data()
              // Include tutorial_completed in userData
              setUserData({
                ...profileData,
                plan_selected: mainUserData.plan_selected,
                tutorial_completed: profileData.tutorial_completed || false
              })

              // Show Pro welcome modal first if applicable
              if (profileData.plan === 'paid' && !profileData.plan_welcome) {
                setShowProWelcomeModal(true)
              } else {
                // Start tour if tutorial not completed
                setRun(!profileData.tutorial_completed)
              }
            } else {
              setUserData({
                intake_completed: false,
                plan_selected: mainUserData.plan_selected,
                tutorial_completed: false
              })
              // Start tour for new users
              setRun(true)
            }

            const applicationsData = await getJobseekerApplications(user.uid)
            setApplications(applicationsData)
          } else {
            console.log('No user document found')
          }
        } else {
          console.log('No user signed in')
        }
      } catch (err) {
        console.error('Error fetching user data:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUserData()
  }, [navigate, location.pathname, refreshParam])

  useEffect(() => {
    if (!showProWelcomeModal && userData && !userData.tutorial_completed) {
      setRun(true)
    }
  }, [showProWelcomeModal, userData])

  // useEffect(() => {
  //   const fetchEmployers = async () => {
  //     try {
  //       setLoadingEmployers(true)
  //       const employerData = await getEmployers()
  //       setEmployers(employerData)
  //     } catch (error) {
  //       setError(error.message)
  //     } finally {
  //       setLoadingEmployers(false)
  //     }
  //   }

  //   fetchEmployers()
  // }, [])

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  // const handleEmployerClick = (employerId) => {
  //   if (userData?.intake_completed) {
  //     navigate(`/employer-profile-view/${employerId}`)
  //   } else {
  //     toast.warning(
  //       'Please complete your profile before accessing employer details.',
  //       {
  //         position: 'top-center',
  //         autoClose: 3000 // Closes after 3 seconds
  //       }
  //     )
  //   }
  // }

  const handleJobClick = (jobId, job, matchPercentage) => {
    if (userData?.intake_completed) {
      navigate(`/job-opening/${jobId}`, {
        state: {
          companyName: job.employer_profile?.company_name,
          companyLogoUrl: job.employerProfile?.company_logo_url,
          matchScore: matchPercentage
        }
      })
    } else {
      toast.warning(
        'Please complete your profile before accessing job details.',
        {
          position: 'top-center',
          autoClose: 3000
        }
      )
    }
  }

  useEffect(() => {
    const fetchEmployersAndJobs = async () => {
      try {
        setLoading(true)

        // Fetch employers
        const employersQuery = query(
          collection(db, 'users'),
          where('role', '==', 'employer')
        )
        const employersSnapshot = await getDocs(employersQuery)

        // Build employer profiles
        const employerProfiles = {}
        for (const employerDoc of employersSnapshot.docs) {
          const userData = employerDoc.data()
          try {
            const profileData = await getEmployerProfile(employerDoc.id)
            if (profileData) {
              const employerId = profileData.employer_id || userData.employer_id
              if (employerId) {
                employerProfiles[employerId] = {
                  company_name:
                    profileData.company_name || userData.company_name,
                  company_logo_url:
                    profileData.company_logo_url || '/default-company-logo.png',
                  company_website: profileData.company_website,
                  industry: profileData.industry
                }
              }
            } else if (userData.employer_id) {
              employerProfiles[userData.employer_id] = {
                company_name: userData.company_name,
                company_logo_url: '/default-company-logo.png'
              }
            }
          } catch (error) {
            if (userData.employer_id) {
              employerProfiles[userData.employer_id] = {
                company_name: userData.company_name,
                company_logo_url: '/default-company-logo.png'
              }
            }
          }
        }

        // Fetch internal jobs
        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('status', '==', 'active')
        )
        const internalSnapshot = await getDocs(jobsQuery)

        // Fetch external jobs
        const externalJobsQuery = query(
          collection(db, 'externalJobs'),
          where('status', '==', 'active')
        )
        const externalSnapshot = await getDocs(externalJobsQuery)

        // Process internal jobs with match scores
        const internalPostings = await Promise.all(
          internalSnapshot.docs.map(async (doc) => {
            const jobData = doc.data()
            const employerId = jobData.employer_id?.toString()
            const employerProfile = employerProfiles[employerId]

            const companyName =
              employerProfile?.company_name &&
              employerProfile.company_name.toLowerCase() !== 'unknown company'
                ? employerProfile.company_name
                : jobData.employer_profile?.company_name || 'Unknown'

            const companyLogo =
              employerProfile?.company_logo_url &&
              employerProfile.company_logo_url !== '/default-company-logo.png'
                ? employerProfile.company_logo_url
                : jobData.employer_profile?.company_logo ||
                  'images/employer-icon.png'

            const matchScore = await Match(
              { id: doc.id, ...jobData },
              userData,
              employerProfile
            )

            return {
              id: doc.id,
              ...jobData,
              matchScore: matchScore?.totalScore || 0,
              employerProfile: {
                company_name: companyName,
                company_logo_url: companyLogo,
                company_website: jobData.employer_profile?.company_website || ''
              },
              source: 'arena'
            }
          })
        )

        // Process external jobs
        const externalPostings = externalSnapshot.docs.map((doc) => {
          const jobData = doc.data()

          // Normalize salary data
          const salary = {
            type: jobData.salary?.type || 'yearly',
            range_start: jobData.salary?.min || 0,
            range_end: jobData.salary?.max || 0
          }

          return {
            id: doc.id,
            ...jobData,
            matchScore: 0,
            employerProfile: {
              company_name: jobData.company_name || 'Unknown',
              company_logo_url: 'images/employer-icon.png',
              company_website: ''
            },
            type: [jobData.work_type?.replace('_', ' ')].filter(Boolean),
            work_policy: 'Not specified',
            department: jobData.category?.label || 'Not specified',
            level: 'Not specified',
            recommended_skills: [],
            benefits: [],
            salary: salary,
            source: 'adzuna'
          }
        })

        // Combine and sort all jobs
        const allPostings = [...internalPostings, ...externalPostings]

        // Update states
        setJobPostings(allPostings)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
        setLoadingJobs(false)
      }
    }

    fetchEmployersAndJobs()
  }, [userData])

  const getMostRecentJobs = (jobPostings, userData) => {
    const pastWeek = new Date()
    pastWeek.setDate(pastWeek.getDate() - 7) // 7 days ago

    if (userData?.plan === 'paid') {
      return [...jobPostings]
        .filter((job) => {
          const jobDate = new Date(
            job.date_posted || formatDate(job.created_at)
          )
          return jobDate >= pastWeek
        })
        .sort((a, b) => b.matchScore - a.matchScore) // of jobs within a week get top 10 scores
        .slice(0, 10)
    }

    // Free users get 10 most recent
    return [...jobPostings]
      .sort((a, b) => {
        const dateA = new Date(a.date_posted || formatDate(a.created_at))
        const dateB = new Date(b.date_posted || formatDate(b.created_at))
        return dateB - dateA
      })
      .slice(0, 10)
  }

  useEffect(() => {
    if (jobPostings.length > 0) {
      setRecentJobPostings(getMostRecentJobs(jobPostings, userData))
    }
  }, [jobPostings])

  const formatSalary = (salary) => {
    if (!salary) return 'Not specified'

    // Handle hidden salary type
    if (salary.type === 'hidden') return 'Undisclosed'

    // Handle unpaid salary type
    if (salary.type === 'unpaid') return 'Unpaid'

    // Format salary range
    const formatNumber = (num) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(num)

    const range = `${formatNumber(salary.range_start)} - ${formatNumber(
      salary.range_end
    )}`
    return salary.type === 'hourly' ? `${range}/hr` : `${range}/year`
  }

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'
    // Convert timestamp to YYYY-MM-DD
    return new Date(timestamp.seconds * 1000).toISOString().split('T')[0]
  }

  const handleUpgradeClick = (e) => {
    e.stopPropagation()
    window.location.href = 'https://buy.stripe.com/28o03yc5z1DcdpK4gu'
  }

  const getMatchColor = (matchPercentage) => {
    if (matchPercentage < 40) return '#e53e3e'
    if (matchPercentage < 60) return '#ed8936'
    if (matchPercentage < 80) return '#ecc94b'
    return '#22c55e'
  }
  // Handlers for tour dashboard button
  const handleStartTour = (e) => {
    e.preventDefault()
    setRun(true)
  }
  const handleJoyrideCallback = async (data) => {
    const { status, index } = data

    if ((status === 'finished' || status === 'skipped') && auth.currentUser) {
      setRun(false)
      setStepIndex(index)

      try {
        // Update tutorial_completed in Firestore
        const profileRef = doc(
          db,
          'users',
          auth.currentUser.uid,
          'profiles',
          'jobseeker'
        )
        await updateDoc(profileRef, {
          tutorial_completed: true
        })

        // Update local state
        setUserData((prev) => ({
          ...prev,
          tutorial_completed: true
        }))
      } catch (error) {
        console.error('Error updating tutorial completion:', error)
      }
    }
  }

  const ApplicationStatusSection = () => (
    <StatusCard>
      <StatusTitle>Applicant Stages</StatusTitle>

      <StageContainer>
        <StageLabel>Reviewed</StageLabel>
        <StageProgressBar>
          <StageProgress width="75%" color="#4ade80" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Interview</StageLabel>
        <StageProgressBar>
          <StageProgress width="45%" color="#60a5fa" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Rejected</StageLabel>
        <StageProgressBar>
          <StageProgress width="25%" color="#f87171" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Unreviewed</StageLabel>
        <StageProgressBar>
          <StageProgress width="55%" color="#fbbf24" />
        </StageProgressBar>
      </StageContainer>
    </StatusCard>
  )

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <Container>
      <ToastContainer />

      <NavWrapper>
        <JobSeekerNav intakeCompleted={userData?.intake_completed} />
      </NavWrapper>
      <MainContent>
        {userData?.plan !== 'paid' && (
          <UpgradeButton
            href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Arena Pro✨
          </UpgradeButton>
        )}
        <ContentContainer>
          <WelcomeHeader>
            {' '}
            Welcome to Arena, {userName}!{' '}
            <ViewApplicationsButton onClick={handleStartTour}>
              Tour Arena
            </ViewApplicationsButton>
          </WelcomeHeader>
          {userData?.plan === 'paid' ? (
            <Card style={{ marginBottom: '1.5rem' }}>
              <SectionTitle>You're on Arena Pro✨!</SectionTitle>
              <h4 style={{ fontWeight: '300' }}>
                Stay ahead of the curve with advanced AI-powered job matching, industry-specific career resources, and exclusive events.
              </h4>
              <h4 style={{ fontWeight: '300' }}>
                🗓️ Monthly Office Hours to get personalized career advice:
                <br></br>
                RSVP for next one on April 2nd, 3pm - 4pm ET:
                <a
                  href="https://lu.ma/81ces23d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register here.
                </a>{' '}
              </h4>
              
            </Card>
          ) : (
            <Card style={{ marginBottom: '1.5rem' }}>
              <SectionTitle>Welcome to Arena! 🎯</SectionTitle>
              <h4 style={{ fontWeight: '300' }}>
                Get started on your journey in sports, media & entertainment:
              </h4>
              <ul style={{ paddingLeft: '20px' }}>
                <li>
                  ⭐️ <strong>Complete Your Profile</strong>
                  <ul>
                    <li>Browse open positions across the industry</li>
                    <li>Set up job alerts for roles you're interested in</li>
                    <li>Track your applications automatically</li>
                  </ul>
                </li>
                <li>
                  💡 <strong>Upgrade to Arena Pro✨ to unlock:</strong>
                  <ul>
                    <li>
                      Smart Job Matching to find your perfect roles faster
                    </li>
                    <li>
                      Resource Hub with curated industry events, books,
                      certifications, courses, newsletters, and podcasts
                    </li>
                    <li>
                      Early access to our AI Resume Builder (coming 2025!)
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <em>
                  Note: We're constantly adding new opportunities in sports,
                  media & entertainment. Complete your profile to stay updated
                  when relevant positions go live!
                </em>
              </p>
              <div style={{ textAlign: 'left', marginTop: '1.5rem' }}>
                <a
                  href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundColor: softColors.icontext,
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}
                >
                  Upgrade to Arena Pro✨
                </a>
              </div>
            </Card>
          )}
          {!userData?.intake_completed && (
            <OnboardingSection
              id="intake-section"
              onClick={() => toggleSection('profile')}
            >
              <SectionHeader>
                <SectionTitle>
                  <SectionIcon>🔥</SectionIcon>
                  Complete your profile to get matched to relevant jobs and
                  opportunities for you.
                </SectionTitle>
                <ChevronDown
                  size={20}
                  style={{
                    transform: openSections.profile ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s',
                    cursor: 'pointer'
                  }}
                />
              </SectionHeader>
              <SectionProgressBar>
                <SectionProgress width="30%" started={true} />
              </SectionProgressBar>
              <SectionContent isOpen={openSections.profile}>
                <Link href="/member-intake">Edit Profile</Link>
              </SectionContent>
            </OnboardingSection>
          )}
          {/* Jobs Applied Section */}
          <Grid columns={2} id="jobs-applied-section">
            {/* Jobs Applied Section */}
            <Card>
              <CardHeader>
                <CardTitle>Jobs Applied</CardTitle>
              </CardHeader>
              <CardContent>
                <p
                  style={{
                    fontSize: '2.25rem',
                    fontWeight: 'bold',
                    color: '#C859FF',
                    marginBottom: '0.5rem'
                  }}
                >
                  {userData?.jobs_applied?.length || 0}
                </p>
                <ViewApplicationsButton
                  onClick={() => navigate('/application-tracking')}
                >
                  View Applications
                </ViewApplicationsButton>
              </CardContent>
            </Card>
            {/* Application Status Section */}
            <Card id="applicant-stages-section">
              <ApplicationStages applications={applications} />
            </Card>
          </Grid>
          {/* Other Sections */}
          {/* MSBC Employers Section */}
          <Card>
            <CardHeader>
              <CardTitle>
                {userData?.plan !== 'paid'
                  ? 'Recent Job Listings'
                  : 'My Top Matches This Week'}
              </CardTitle>
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Job Info</TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Match Score
                  </TableHeader>
                </tr>
              </thead>
              {loadingJobs ? (
                <tbody>
                  {[...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <TableCell>
                        <TableSubCell>
                          <SkeletonText width="30rem" maxWidth="30rem" />
                        </TableSubCell>
                      </TableCell>
                      <TableCell>
                        <TableSubCell>
                          <SkeletonText width="10rem" maxWidth="15rem" />
                        </TableSubCell>
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {recentJobPostings.map((job) => (
                    <tr
                      key={job.id}
                      onClick={() => handleJobClick(job.id, job)} // Make the entire row clickable
                      style={{
                        cursor: 'pointer',
                        transition: 'background-color 0.2s',
                        borderBottom: '1px solid #e2e8f0'
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor = '#f0f4f8')
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor = '#ffffff')
                      }
                    >
                      <TableCell>
                        <CompanyInfo>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <JobTitle>{job.title}</JobTitle>

                            <JobMetadata
                              style={{
                                fontWeight: '600',
                                marginBottom: '0.5rem',
                                marginTop: '0rem'
                              }}
                            >
                              {job.employerProfile?.company_name?.toLowerCase() !==
                              'unknown company'
                                ? job.employerProfile.company_name
                                : 'Arena Talent'}
                            </JobMetadata>

                            <JobMetadata>
                              {job.location &&
                                job.location !== 'Not specified' && (
                                  <div>
                                    <MapPin size={16} />
                                    {job.location}
                                  </div>
                                )}
                              {job.level && job.level !== 'Not specified' && (
                                <div>
                                  <Briefcase size={16} />
                                  {job.level}
                                </div>
                              )}
                              {job.work_policy &&
                                job.work_policy !== 'Not specified' && (
                                  <div>
                                    <Laptop size={16} />
                                    {job.work_policy}
                                  </div>
                                )}
                              {(job.type || job.work_type) && (
                                <div>
                                  <Clock size={16} />
                                  {job.type
                                    ? Array.isArray(job.type)
                                      ? job.type
                                          .map((t) =>
                                            t.toLowerCase() === 'full time' ||
                                            t === 'full_time'
                                              ? 'Full-Time'
                                              : t
                                          )
                                          .join(', ')
                                      : job.type.toLowerCase() ===
                                          'full time' ||
                                        job.type === 'full_time'
                                      ? 'Full-Time'
                                      : job.type
                                    : job.work_type.toLowerCase() ===
                                        'full time' ||
                                      job.work_type === 'full_time'
                                    ? 'Full-Time'
                                    : job.work_type}
                                </div>
                              )}

                              <div>
                                <BanknoteIcon size={16} />{' '}
                                {formatSalary(job.salary)}
                              </div>
                            </JobMetadata>
                          </div>
                        </CompanyInfo>
                      </TableCell>

                      <TableCell>
                        <JobMetadata style={{ justifyContent: 'center' }}>
                          {/* Locked Bubble with Stripe for Free users */}
                          {userData?.plan !== 'paid' ? (
                            <MatchBubble
                              href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <TooltipContainer>
                                <Lock size={16} />
                                <TooltipText>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: '12px',
                                        textAlign: 'center'
                                      }}
                                    >
                                      Upgrade to Arena Pro to see all match
                                      scores and unlock personalized job
                                      recommendations.
                                    </div>
                                    <button
                                      onClick={handleUpgradeClick}
                                      style={{
                                        backgroundColor: '#caaae1',
                                        color: 'white',
                                        padding: '8px 16px',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                      }}
                                    >
                                      Upgrade to Pro
                                    </button>
                                  </div>
                                </TooltipText>
                              </TooltipContainer>
                            </MatchBubble>
                          ) : (
                            // Match scores for paid users
                            <MatchBubble
                              style={{
                                backgroundColor: getMatchColor(job.matchScore)
                              }}
                            >
                              {job.matchScore}% Match
                            </MatchBubble>
                          )}
                        </JobMetadata>
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </Card>

          <Card id="ai-transparency-section" style={{ marginTop: '2rem' }}>
            <CardTitle>How Our AI Works</CardTitle>
            <p
              style={{
                fontSize: '0.875rem',
                color: softColors.textLight,
                lineHeight: '1.5'
              }}
            >
              At Arena, we believe in transparency and equity throughout the
              hiring process. Our scoring system is designed to benefit both
              employers and members. Here's a comprehensive look at how we
              evaluate candidates.{' '}
              <a
                href="https://arenatalent.notion.site/arena-methodology-overview"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: softColors.linkColor,
                  textDecoration: 'underline'
                }}
              >
                Click here to view Arena's AI Methodology
              </a>
            </p>
          </Card>
        </ContentContainer>
      </MainContent>
      <NotificationPanel
        isPaidUser={userData?.plan === 'paid'}
        isPresaleUser={userData?.presale}
      />

      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback}
        continuous={true}
        disableOverlayClose={true}
        scrollToFirstStep
        showSkipButton
        locale={{
          last: 'Finish'
        }}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: '#C471ED'
          },
          buttonClose: {
            display: 'none'
          },
          tooltip: {
            borderRadius: 8,
            maxWidth: '100%',
            padding: 15
          },
          spotlight: {
            borderRadius: 12
          },
          buttonNext: {
            borderColor: '#C471ED',
            borderStyle: 'solid',
            borderWidth: '2px',
            boxShadow: 'none',
            backgroundColor: '#C471ED',
            color: '#fff'
          }
        }}
      />
      <ProWelcomeModal
        isOpen={showProWelcomeModal}
        onClose={() => setShowProWelcomeModal(false)}
        userId={auth.currentUser?.uid}
      />
    </Container>
  )
}
