// src/components/Admin/AdminDashboard.js
import React, { useState } from 'react'
import styled from 'styled-components'
import CouponsTab from './CouponsTab'
import EmployersTab from './EmployersTab'
import JobSeekersTab from './JobSeekersTab' // Import the new tab component
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  padding: 20px;
  color: white;
  min-height: 100vh;
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
`

const TabContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`

const TabButton = styled.button`
  padding: 10px 20px;
  background: ${(props) => (props.active ? '#8b5cf6' : 'transparent')};
  border: 1px solid #8b5cf6;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${(props) =>
      props.active ? '#8b5cf6' : 'rgba(139, 92, 246, 0.1)'};
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 16px;
  margin-bottom: 20px;
`

const BackButton = styled.button`
  display: flex;
  width: 100px;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('employers')
  const navigate = useNavigate()

  return (
    <Container>
      <Header>
        <BackButton onClick={() => navigate(-1)}>← Back</BackButton>
        <h1>Admin Dashboard</h1>
      </Header>
      <TabContainer>
        <TabButton
          active={activeTab === 'employers'}
          onClick={() => setActiveTab('employers')}
        >
          Employers
        </TabButton>
        <TabButton
          active={activeTab === 'coupons'}
          onClick={() => setActiveTab('coupons')}
        >
          Coupons
        </TabButton>
        <TabButton
          active={activeTab === 'jobseekers'}
          onClick={() => setActiveTab('jobseekers')}
        >
          Members
        </TabButton>
      </TabContainer>
      {activeTab === 'coupons' && <CouponsTab />}
      {activeTab === 'employers' && <EmployersTab />}
      {activeTab === 'jobseekers' && <JobSeekersTab />}
    </Container>
  )
}

export default AdminDashboard
