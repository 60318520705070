import { db, auth, storage } from '../firebase'
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  addDoc,
  getDocs,
  query,
  runTransaction,
  where,
  limit,
  writeBatch,
  arrayUnion,
  arrayRemove,
  deleteField
} from 'firebase/firestore'

import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { getUserById } from './User'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const EMPLOYER_PROFILE_DOC = 'employer'
const EMPLOYER_MEMBERS_COLLECTION = 'employerMembers'
const JOB_POSTINGS_COLLECTION = 'jobPostings'
const JOBSEEKER_PROFILE_DOC = 'jobseeker'

// EmployerProfile data structure
const createEmployerProfileObject = (profileData) => {
  const profile = {
    plan_type: profileData.plan_type ?? 'freetrial',
    company_website: profileData.company_website ?? null,
    company_address: profileData.company_address ?? null,
    company_phone: profileData.company_phone ?? null,
    employer_id: profileData.employer_id ?? null,
    company_description: profileData.company_description ?? null,
    company_instagram: profileData.company_instagram ?? null,
    company_tiktok: profileData.company_tiktok ?? null,
    company_youtube: profileData.company_youtube ?? null,
    company_x: profileData.company_x ?? null,
    website: profileData.website ?? null,
    location: profileData.location ?? null,
    locations: profileData.locations ?? [],
    work_from_home_policy: profileData.work_from_home_policy ?? null,
    top_ranking: profileData.top_ranking ?? null,
    linkedin: profileData.linkedin ?? null,
    industry: profileData.industry ?? null,
    subindustry: profileData.subindustry ?? null,
    number_of_open_jobs: profileData.number_of_open_jobs ?? 0,
    pipelines: profileData.pipelines ?? {
      Favorites: [] // Default list
    },
    open_jobs: profileData.open_jobs ?? [],
    number_of_hires: profileData.number_of_hires ?? 0,
    benefits: profileData.benefits ?? [],
    recent_news: profileData.recent_news ?? null,
    team: profileData.team ?? null,
    profile_picture_url: profileData.profile_picture_url ?? null,
    cover_photo_url: profileData.cover_photo_url ?? null,
    logo: profileData.logo ?? null,
    photos: profileData.photos ?? [],
    video: profileData.video ?? null,
    company_facebook: profileData.company_facebook ?? null,
    additional_url: profileData.additional_url ?? null,
    full_time_employees: profileData.full_time_employees ?? null,
    expected_full_time_hires: profileData.expected_full_time_hires ?? null,
    expected_part_time_hires: profileData.expected_part_time_hires ?? null,
    hiring_goals: profileData.hiring_goals ?? {},
    hiring_challenges: profileData.hiring_challenges ?? [],
    candidate_qualities: profileData.candidate_qualities ?? [],
    company_logo_url: profileData.company_logo_url ?? null,
    intake_completed: profileData.intake_completed ?? false,
    willing_to_sponsor: profileData.willing_to_sponsor ?? null,

    //tutorials
    dashboard_tutorial_completed:
      profileData.dashboard_tutorial_completed || false,
    sourcing_tutorial_completed:
      profileData.sourcing_tutorial_completed || false,
    employer_profile_tutorial_completed:
      profileData.employer_profile_tutorial_completed || false,
    pipelines_tutorial_completed:
      profileData.pipelines_tutorial_completed || false,

    updated_at: serverTimestamp()
  }

  // Only include company_name if it's provided
  if (profileData.company_name) {
    profile.company_name = profileData.company_name
  }

  return profile
}

// Retrieve job postings by employer_id
export const getJobPostingsByEmployerId = async (employerId) => {
  try {
    const jobsQuery = query(
      collection(db, JOB_POSTINGS_COLLECTION),
      where('employer_id', '==', employerId),
      where('status', '==', 'active')
    )
    const jobsSnapshot = await getDocs(jobsQuery)
    return jobsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
  } catch (error) {
    console.error('Error fetching job postings:', error)
    throw error
  }
}

// Retrieve job seekers who favorited an employer
export const getJobSeekersWhoFavoritedEmployer = async (employerId) => {
  try {
    const jobSeekersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'jobseeker')
    )
    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)

    const jobSeekers = []

    for (const docSnapshot of jobSeekersSnapshot.docs) {
      const userId = docSnapshot.id
      const profileRef = doc(
        db,
        USERS_COLLECTION,
        userId,
        PROFILE_SUBCOLLECTION,
        'jobseeker'
      )
      const profileSnap = await getDoc(profileRef)

      if (profileSnap.exists()) {
        const profileData = profileSnap.data()
        if (
          profileData.favorites &&
          profileData.favorites.includes(employerId)
        ) {
          jobSeekers.push({
            id: userId,
            ...profileData
          })
        }
      }
    }
    return jobSeekers
  } catch (error) {
    console.error('Error fetching job seekers who favorited employer:', error)
    throw error
  }
}

// Fetch employer profile
export const getEmployerProfile = async (userId) => {
  const userData = await getUserById(userId)
  if (!userData) {
    throw new Error('User not found')
  }

  const profileRef = doc(
    db,
    USERS_COLLECTION,
    userId,
    PROFILE_SUBCOLLECTION,
    EMPLOYER_PROFILE_DOC
  )

  const profileSnap = await getDoc(profileRef)

  if (profileSnap.exists()) {
    const profileData = profileSnap.data()
    return {
      ...profileData,
      company_name:
        profileData.company_name ||
        userData.company_name ||
        'Company Name Not Set'
    }
  } else {
    const defaultProfile = {
      plan_type: 'freetrial',
      company_name: userData.company_name || 'Company Name Not Set',
      created_at: serverTimestamp(),
      updated_at: serverTimestamp()
    }

    await setDoc(profileRef, defaultProfile)

    return defaultProfile
  }
}

export const getEmployerMembers = async (employerId) => {
  try {
    const membersQuery = query(
      collection(db, 'employerMembers'),
      where('employer_id', '==', employerId)
    )
    const membersSnapshot = await getDocs(membersQuery)
    return membersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error fetching employer members:', error)
    throw error
  }
}

// Get job seekers
export const getJobSeekers = async () => {
  try {
    const jobSeekersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'jobseeker')
    )
    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)
    const jobSeekers = []

    for (const docSnapshot of jobSeekersSnapshot.docs) {
      const userData = docSnapshot.data()

      const jobSeekerProfileRef = doc(
        db,
        USERS_COLLECTION,
        docSnapshot.id,
        PROFILE_SUBCOLLECTION,
        JOBSEEKER_PROFILE_DOC
      )
      const jobSeekerProfileSnap = await getDoc(jobSeekerProfileRef)
      let jobSeekerProfileData = {}

      if (jobSeekerProfileSnap.exists()) {
        jobSeekerProfileData = jobSeekerProfileSnap.data()
      } else {
        console.warn(
          `No profile data found for job seeker ID: ${docSnapshot.id}`
        )
      }

      jobSeekers.push({
        id: docSnapshot.id,
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        created_at: userData.created_at || null,
        updated_at: userData.updated_at || null,
        role: userData.role || 'jobseeker',
        pronouns: jobSeekerProfileData.pronouns || '',
        ...jobSeekerProfileData
      })
    }

    return jobSeekers
  } catch (error) {
    console.error('Error in getJobSeekers:', error)
    return []
  }
}

// Function to add a new pipeline
export const addPipeline = async (employerId, pipelineName) => {
  const profileRef = doc(
    db,
    USERS_COLLECTION,
    employerId,
    PROFILE_SUBCOLLECTION,
    EMPLOYER_PROFILE_DOC
  )
  await updateDoc(profileRef, {
    [`pipelines.${pipelineName}`]: [] // Initialize the new pipeline as an empty array
  })
}

// Function to add job seekers to a specific pipeline
export const addJobSeekerToPipeline = async (
  employerId,
  pipelineName,
  jobSeekerId
) => {
  const profileRef = doc(
    db,
    USERS_COLLECTION,
    employerId,
    PROFILE_SUBCOLLECTION,
    EMPLOYER_PROFILE_DOC
  )
  await updateDoc(profileRef, {
    [`pipelines.${pipelineName}`]: arrayUnion(jobSeekerId)
  })
}

export const deletePipelineFromFirestore = async (employerId, pipelineName) => {
  const profileRef = doc(
    db,
    USERS_COLLECTION,
    employerId,
    PROFILE_SUBCOLLECTION,
    EMPLOYER_PROFILE_DOC
  )

  await updateDoc(profileRef, {
    [`pipelines.${pipelineName}`]: arrayRemove()
  })
}

export const getPipelinesFromFirestore = async (employerId) => {
  const profileRef = doc(
    db,
    USERS_COLLECTION,
    employerId,
    PROFILE_SUBCOLLECTION,
    EMPLOYER_PROFILE_DOC
  )
  const profileSnap = await getDoc(profileRef)

  if (profileSnap.exists()) {
    return profileSnap.data().pipelines || {} // Return pipelines or empty object if not present
  } else {
    console.error('No profile found for employer:', employerId)
    return {}
  }
}

// Upload company logo and update employer profile
// export const uploadCompanyLogo = async (file) => {
//   try {
//     const user = auth.currentUser
//     if (!user) throw new Error('No authenticated user')

//     // Check if the current user is an employer member
//     const employerMemberDoc = await getDoc(
//       doc(db, EMPLOYER_MEMBERS_COLLECTION, user.uid)
//     )
//     let employerId = user.uid

//     if (employerMemberDoc.exists()) {
//       // If the user is an employer member, use the employer_id from their document
//       employerId = employerMemberDoc.data().employer_id
//       if (!employerId) {
//         throw new Error('Employer member document does not have an employer_id')
//       }
//       console.log('User is an employer member. Using employer ID:', employerId)
//     }

//     const fileName = `${Date.now()}_${file.name}`
//     const storageRef = ref(storage, `company_logos/${employerId}/${fileName}`)

//     console.log('Uploading file to:', storageRef.fullPath)

//     const snapshot = await uploadBytes(storageRef, file)
//     console.log('File uploaded successfully')

//     const downloadURL = await getDownloadURL(snapshot.ref)
//     console.log('Download URL obtained:', downloadURL)

//     // Update the employer profile with the new logo URL
//     const employerProfileRef = doc(
//       db,
//       USERS_COLLECTION,
//       employerId,
//       PROFILE_SUBCOLLECTION,
//       EMPLOYER_PROFILE_DOC
//     )
//     await updateDoc(employerProfileRef, { company_logo_url: downloadURL })
//     console.log('Company logo URL updated in profile')

//     return downloadURL
//   } catch (error) {
//     console.error('Error uploading company logo:', error)
//     if (error.code === 'storage/unauthorized') {
//       console.error(
//         'Storage unauthorized. Please check Firebase Storage rules.'
//       )
//     }
//     throw error
//   }
// }

export const createOrUpdateEmployerProfile = async (profileData) => {
  try {
    const user = auth.currentUser
    if (!user) throw new Error('No authenticated user')

    // Fetch the user document
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, user.uid))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }
    const userData = userDoc.data()

    let employerId = userData.employer_id || user.uid

    // Create the updated profile object
    const updatedData = {
      ...profileData,
      updated_at: serverTimestamp(),
      intake_completed: true,
      employer_id: employerId // Ensure employer_id is included in the profile
    }

    const batch = writeBatch(db)

    // Update the employer's profile
    const employerProfileRef = doc(
      db,
      USERS_COLLECTION,
      user.uid,
      PROFILE_SUBCOLLECTION,
      EMPLOYER_PROFILE_DOC
    )
    batch.set(employerProfileRef, updatedData, { merge: true })

    // Update the user document with the employer_id
    batch.update(doc(db, USERS_COLLECTION, user.uid), {
      employer_id: employerId
    })

    // Fetch all users with the same employer_id
    const usersQuery = query(
      collection(db, USERS_COLLECTION),
      where('employer_id', '==', employerId)
    )
    const usersSnapshot = await getDocs(usersQuery)

    // Update profiles for all users with the same employer_id
    usersSnapshot.forEach((userDoc) => {
      if (userDoc.id !== user.uid) {
        // Don't update the current user again
        const userProfileRef = doc(
          db,
          USERS_COLLECTION,
          userDoc.id,
          PROFILE_SUBCOLLECTION,
          EMPLOYER_PROFILE_DOC
        )
        batch.set(userProfileRef, updatedData, { merge: true })
      }
    })

    // Commit all the updates
    await batch.commit()

    return employerProfileRef
  } catch (error) {
    console.error('Error in createOrUpdateEmployerProfile:', error)
    throw error
  }
}

// Basic file validation
const validateFile = (file, type = 'any') => {
  const allowedTypes = {
    image: ['image/jpeg', 'image/png', 'image/gif'],
    video: ['video/mp4', 'video/quicktime', 'video/x-msvideo']
  }

  if (type !== 'any' && !allowedTypes[type]?.includes(file.type)) {
    throw new Error(
      `Invalid file type. Allowed types: ${allowedTypes[type].join(', ')}`
    )
  }

  return true
}

// Check video duration
export const checkVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      if (video.duration > 240) {
        // 4 minutes
        reject('Video must be less than 4 minutes long')
      } else {
        resolve(video.duration)
      }
    }

    video.onerror = () => {
      reject('Error verifying video duration')
    }

    video.src = URL.createObjectURL(file)
  })
}

export const uploadCompanyVideo = async (file, onProgress) => {
  try {
    const user = auth.currentUser
    if (!user) throw new Error('No authenticated user')

    // Validate file size
    if (file.size > 100 * 1024 * 1024) {
      // 100MB
      throw new Error('Video file must be less than 100MB')
    }

    // Validate file type
    validateFile(file, 'video')

    // Check duration
    await checkVideoDuration(file)

    const userDoc = await getDoc(doc(db, 'users', user.uid))
    if (!userDoc.exists()) throw new Error('User document not found')
    const userData = userDoc.data()
    const employerId = userData.employer_id || user.uid

    const fileName = `${Date.now()}_${file.name}`
    const storageRef = ref(storage, `company_videos/${employerId}/${fileName}`)

    // Create upload task
    const uploadTask = uploadBytesResumable(storageRef, file)

    // Handle progress updates
    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      if (onProgress) onProgress(progress)
    })

    // Wait for upload to complete
    await uploadTask

    // Get download URL
    const downloadURL = await getDownloadURL(storageRef)

    // Update profile with video URL
    await updateDoc(doc(db, 'users', employerId, 'profiles', 'employer'), {
      video_url: downloadURL,
      updated_at: serverTimestamp()
    })

    return downloadURL
  } catch (error) {
    console.error('Error uploading company video:', error)
    throw error
  }
}

export const uploadCompanyLogo = async (file, onProgress) => {
  try {
    const user = auth.currentUser
    if (!user) throw new Error('No authenticated user')

    // Validate file size
    if (file.size > 5 * 1024 * 1024) {
      // 5MB
      throw new Error('Logo file must be less than 5MB')
    }

    // Validate file type
    validateFile(file, 'image')

    const userDoc = await getDoc(doc(db, 'users', user.uid))
    if (!userDoc.exists()) throw new Error('User document not found')
    const userData = userDoc.data()
    const employerId = userData.employer_id || user.uid

    const fileName = `${Date.now()}_${file.name}`
    const storageRef = ref(storage, `company_logos/${employerId}/${fileName}`)

    // Create upload task
    const uploadTask = uploadBytesResumable(storageRef, file)

    // Handle progress updates
    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      if (onProgress) onProgress(progress)
    })

    // Wait for upload to complete
    await uploadTask

    // Get download URL
    const downloadURL = await getDownloadURL(storageRef)

    // Update profile with logo URL
    await updateDoc(doc(db, 'users', employerId, 'profiles', 'employer'), {
      company_logo_url: downloadURL,
      updated_at: serverTimestamp()
    })

    return downloadURL
  } catch (error) {
    console.error('Error uploading company logo:', error)
    throw error
  }
}
export const moveJobSeekerBetweenPipelines = async (
  employerId,
  sourcePipeline,
  destinationPipeline,
  jobSeekerId,
  destinationIndex
) => {
  const employerRef = doc(db, 'employers', employerId)

  try {
    await runTransaction(db, async (transaction) => {
      const employerDoc = await transaction.get(employerRef)

      if (!employerDoc.exists()) {
        throw new Error('Employer document does not exist!')
      }

      const data = employerDoc.data()
      const pipelines = data.pipelines || {}

      // Remove from source pipeline
      const sourceCandidates = Array.from(pipelines[sourcePipeline] || [])
      const candidateIndex = sourceCandidates.indexOf(jobSeekerId)
      if (candidateIndex > -1) {
        sourceCandidates.splice(candidateIndex, 1)
      }

      // Add to destination pipeline
      const destCandidates = Array.from(pipelines[destinationPipeline] || [])
      destCandidates.splice(destinationIndex, 0, jobSeekerId)

      // Update both pipelines in a single transaction
      transaction.update(employerRef, {
        [`pipelines.${sourcePipeline}`]: sourceCandidates,
        [`pipelines.${destinationPipeline}`]: destCandidates
      })
    })
  } catch (error) {
    console.error('Error moving job seeker between pipelines:', error)
    throw error
  }
}

export const removeJobSeekerFromPipeline = async (
  employerId,
  pipelineName,
  jobSeekerId
) => {
  try {
    const employerRef = doc(
      db,
      USERS_COLLECTION,
      employerId,
      PROFILE_SUBCOLLECTION,
      EMPLOYER_PROFILE_DOC
    )

    await updateDoc(employerRef, {
      [`pipelines.${pipelineName}`]: arrayRemove(jobSeekerId)
    })

    return true
  } catch (error) {
    console.error('Error removing job seeker from pipeline:', error)
    throw error
  }
}
