import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const StatusCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const StatusTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 1.5rem;
`

const StageContainer = styled.div`
  margin-bottom: 1.25rem;
`

const StageLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #4a5568;
`

const StageProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #edf2f7;
  border-radius: 4px;
  overflow: hidden;
`

const StageProgress = styled.div`
  height: 100%;
  border-radius: 4px;
  width: ${(props) => props.width}%;
  transition: width 0.3s ease;
`

const MatchesInfo = styled.div`
  color: #666;
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`

const ReviewButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #b347e5;
  }
`
const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: absolute;
  top: 1;
  margin-top: 10px;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

const ApplicationStages = ({ applications = [] }) => {
  const navigate = useNavigate()
  const [stageData, setStageData] = useState({
    submitted: 0,
    reviewed: 0,
    shortlisted: 0,
    interview: 0,
    offer: 0,
    rejected: 0,
    withdrawn: 0
  })

  useEffect(() => {
    // Debug log to see incoming applications

    const counts = applications.reduce(
      (acc, application) => {
        // Debug log for each application

        if (!application) return acc

        const status = application.status || 'submitted'
        acc[status] = (acc[status] || 0) + 1

        // Debug log for updated counts

        return acc
      },
      {
        submitted: 0,
        reviewed: 0,
        shortlisted: 0,
        interview: 0,
        offer: 0,
        rejected: 0,
        withdrawn: 0
      }
    )

    // Debug log final counts
    setStageData(counts)
  }, [applications])

  const total = Object.values(stageData).reduce((acc, curr) => acc + curr, 0)
  const getPercentage = (value) => (total === 0 ? 0 : (value / total) * 100)

  // Debug log current stage data

  return (
    <StatusCard>
      <StatusTitle>Applicant Stages</StatusTitle>

      <StageContainer>
        <StageLabel>
          <span>Submitted</span>
          <span>{stageData.submitted}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.submitted)}
            style={{ backgroundColor: '#3B82F6' }}
          />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>
          <span>Reviewed</span>
          <span>{stageData.reviewed}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.reviewed)}
            style={{ backgroundColor: '#10B981' }}
          />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>
          <span>Shortlisted</span>
          <span>{stageData.shortlisted}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.shortlisted)}
            style={{ backgroundColor: '#F59E0B' }}
          />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>
          <span>Interview</span>
          <span>{stageData.interview}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.interview)}
            style={{ backgroundColor: '#8B5CF6' }}
          />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>
          <span>Offer</span>
          <span>{stageData.offer}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.offer)}
            style={{ backgroundColor: '#059669' }}
          />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>
          <span>Rejected</span>
          <span>{stageData.rejected}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.rejected)}
            style={{ backgroundColor: '#EF4444' }}
          />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>
          <span>Withdrawn</span>
          <span>{stageData.withdrawn}</span>
        </StageLabel>
        <StageProgressBar>
          <StageProgress
            width={getPercentage(stageData.withdrawn)}
            style={{ backgroundColor: '#6B7280' }}
          />
        </StageProgressBar>
      </StageContainer>

      {/* {stageData.submitted > 0 && (
        <>
          <MatchesInfo>
            You have {stageData.submitted} unreviewed applications
          </MatchesInfo>
          <ReviewButton onClick={() => navigate('/applicants-review')}>
            Review Now
          </ReviewButton>
        </>
      )} */}
    </StatusCard>
  )
}

export default ApplicationStages
