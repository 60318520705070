import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Search, Save, ChevronDown, ChevronUp, Heart, X, Check } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { getEmployers } from '../../models/User'
import { useNavigate } from 'react-router-dom'

import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'
import { auth } from '../../firebase'
import { getDoc, doc } from 'firebase/firestore'
import { db } from '../../firebase'

import { getEmployerProfile } from '../../models/EmployerProfile'

// Constants
const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'Esports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}
const workPolicyOptions = ['In-Office', 'Remote', 'Hybrid']
const employeeRanges = [
  { label: '1-10', min: 1, max: 10 },
  { label: '11-50', min: 11, max: 50 },
  { label: '51-200', min: 51, max: 200 },
  { label: '201-500', min: 201, max: 500 },
  { label: '500+', min: 500, max: Infinity }
]

// Styled Components
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: -1rem;
  }
`

const SearchBar = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2d3748;
  }
`

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #caaae1;

  &:hover {
    background-color: #3182ce;
  }
  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`

const SearchButtonText = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const SubcategorySection = styled.div`
  margin-left: 1.5rem;
  margin-top: 0.5rem;
`

const ClearFilterButton = styled.button`
  color: #4299e1;
  font-size: 0.875rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`

// Company list styled components
const CompanyList = styled.div`
  flex-grow: 1;
`

const CompanyListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

const CompanyCard = styled.div`
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1.5rem;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const HeartIcon = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
`
const LoadingMessage = styled.div`
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #4a5568;
`

const ErrorMessage = styled.div`
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #e53e3e;
`

const CompanyInfo = styled.div`
  display: flex;
  gap: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const LogoPlaceholder = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
  border-radius: 0.375rem;
  color: #a0aec0;
  font-size: 0.875rem;
`

const CompanyLogo = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: contain;
  border-radius: 0.375rem;
  background-color: #f7fafc;
`

const CompanyDetails = styled.div`
  flex: 1;
`

const CompanyName = styled.h3`
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #2d3748;
`

const JobCount = styled.p`
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 0.75rem;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Tag = styled.span`
  background-color: #edf2f7;
  color: #4a5568;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
`

const PartnerBadge = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #9f7aea;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 250px; 

  height: fit-content;
  position: sticky;
  top: 2rem;
  @media (max-width: 768px) {
    width: auto;
  }
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #1a202c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease;

  &:hover {
    color: #caaae1;
  }

  svg {
    transition: transform 0.2s ease;
  }
`

const FilterSection = styled.div`
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
`

const FilterContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '300px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  margin-left: 0.5rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7f7f7;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const CheckboxLabel = styled(RadioLabel)`
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const FilterCount = styled.span`
  color: #718096;
  font-size: 0.75rem;
  margin-left: auto;
  background-color: #f7fafc;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const ClearButton = styled.button`
  color: #caaae1;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.375rem;

  &:hover {
    background-color: #f7f7f7;
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 400px;
  position: relative;
`

const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2d3748;
`

const ModalClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;

  &:hover {
    color: #2d3748;
  }
`

const DeleteButton = styled.button`
  color: #e53e3e;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: #fed7d7;
  }
`

const SavedFiltersList = styled(FilterSection)`
  margin-top: 1rem;
`

const SavedFiltersContent = styled(FilterContent)`
  padding: 0.5rem 0;
`

const SavedFilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  background-color: #f7fafc;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #edf2f7;
  }
`

const SaveFilterButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin: 1rem 0;
  background-color: #caaae1;

  &:hover {
    background-color: #b794f4;
  }
`

const Disclaimer = styled.div`
  background-color: #f9fafb;
  border: 1px solid #e2e8f0;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: absolute;
  top: 0;
  margin-top: 10px;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`
const UpgradeButton = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #d29856;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  z-index: 1001; // Added to ensure bubble appears above other content
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
  }
`

const SortSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background-color: white;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #caaae1;
  }
`
const ButtonGroup = styled.div`
  display: flex;
  text-align: center;
  align-self: flex-start;
`

const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 0.875rem;
  font-weight: ${(props)=> props.isActive ? 600 : 400};
  padding: 0.5rem 10px;
  width: ${(props) => props.width || '75px'};
  background-color: ${(props) =>
    props.isActive ? '#edf2f7' : 'white'};
   border: 1px solid #e2e8f0;
  border-left: ${(props) =>
    props.first
      ? '1px solid #e2e8f0'
      : 'none'}; /* Only first button gets left border */
  border-radius: ${(props) => props.borderRadius || '0.5rem'};
  color: #4a5568;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-radius 0.3s ease;
`
const TabSwitch = ({ viewFavorites, setViewFavorites, companies, favorites }) => {
  return (
    <ButtonGroup>
      <TabButton
        onClick={() => setViewFavorites(false)}
        isActive={!viewFavorites}
        width='max-content'
        first
        borderRadius="0.5rem 0 0 0.5rem"
      >
        {!viewFavorites && <Check size={14} />}
        All Companies ({companies.length})
      </TabButton>
      <TabButton
        onClick={() => setViewFavorites(true)}
        isActive={viewFavorites}
        width='max-content'
        borderRadius='0 0.5rem 0.5rem 0'
      >
        {viewFavorites && <Check size={14} />}
        Favorite Companies ({favorites.length})
      </TabButton>
    </ButtonGroup>
  )
}
export default function CompanySearch() {
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOption, setSortOption] = useState('recent')
  const [companies, setCompanies] = useState([])
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSubcategories, setSelectedSubcategories] = useState([])
  const [selectedEmployeeCount, setSelectedEmployeeCount] = useState('')
  const [profile, setProfile] = useState(null)

  const [selectedWorkPolicy, setSelectedWorkPolicy] = useState('')
  const [favorites, setFavorites] = useState([])
  const [viewFavorites, setViewFavorites] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterName, setFilterName] = useState('')
  const [savedFilters, setSavedFilters] = useState([])
  const [openFilters, setOpenFilters] = useState({
    industry: true,
    employeeCount: true,
    workPolicy: true
  })
  const [isPaidUser, setIsPaidUser] = useState(false)
  const [selectedSort, setSelectedSort] = useState('recent')

  useEffect(() => {
    fetchCompanies()
  }, [])

  useEffect(() => {
    filterCompanies()
  }, [
    searchTerm,
    sortOption,
    companies,
    selectedIndustries,
    selectedSubcategories,
    selectedEmployeeCount,
    selectedWorkPolicy,
    favorites,
    selectedSort,
    viewFavorites
  ])

  useEffect(() => {
    const checkUserPlan = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        try {
          const userDoc = await getDoc(
            doc(db, 'users', userId, 'profiles', 'jobseeker')
          )
          if (userDoc.exists()) {
            setIsPaidUser(userDoc.data()?.plan === 'paid')
          }
        } catch (error) {
          console.error('Error checking user plan:', error)
        }
      }
    }

    checkUserPlan()
  }, [])

  const fetchProfile = async (userId) => {
    try {
      const profileData = await getJobseekerProfile(userId)
      setProfile(profileData) // Add this line
      setFavorites(
        Array.isArray(profileData?.favorites) ? profileData.favorites : []
      )
    } catch (error) {
      console.error('Error fetching profile:', error)
      setFavorites([])
    }
  }

  useEffect(() => {
    const userId = auth.currentUser?.uid
    if (userId) {
      fetchProfile(userId)
    }
  }, [])

  useEffect(() => {
    const userId = auth.currentUser?.uid
    if (userId) {
      fetchSavedFilters(userId)
    }
  }, [])

  // Add function to fetch saved filters
  const fetchSavedFilters = async (userId) => {
    try {
      const profile = await getJobseekerProfile(userId)
      setSavedFilters(profile?.saved_company_filters || [])
    } catch (error) {
      console.error('Error fetching saved filters:', error)
      setSavedFilters([])
    }
  }

  // Add function to save current filter configuration
  const saveCurrentFilter = async () => {
    if (!filterName.trim()) {
      setError('Please enter a name for your filter')
      return
    }

    const userId = auth.currentUser?.uid
    if (!userId) return

    const newFilter = {
      id: Date.now().toString(),
      name: filterName.trim(),
      configuration: {
        selectedIndustries,
        selectedSubcategories,
        selectedEmployeeCount,
        selectedWorkPolicy
      }
    }

    try {
      const updatedFilters = [...savedFilters, newFilter]
      await updateJobseekerProfile(userId, {
        saved_company_filters: updatedFilters
      })
      setSavedFilters(updatedFilters)
      setIsModalOpen(false)
      setFilterName('')
      setError(null)
    } catch (error) {
      console.error('Error saving filter:', error)
      setError('Failed to save filter. Please try again.')
    }
  }

  // Add function to delete saved filter
  const deleteFilter = async (filterId) => {
    const userId = auth.currentUser?.uid
    if (!userId) return

    try {
      const updatedFilters = savedFilters.filter(
        (filter) => filter.id !== filterId
      )
      await updateJobseekerProfile(userId, {
        saved_company_filters: updatedFilters
      })
      setSavedFilters(updatedFilters)
    } catch (error) {
      console.error('Error deleting filter:', error)
    }
  }

  // Add function to apply saved filter
  const applyFilter = (filter) => {
    const { configuration } = filter
    setSelectedIndustries(configuration.selectedIndustries)
    setSelectedSubcategories(configuration.selectedSubcategories)
    setSelectedEmployeeCount(configuration.selectedEmployeeCount)
    setSelectedWorkPolicy(configuration.selectedWorkPolicy)
  }

  const handleCompanyClick = (companyId) => {
    navigate(`/employer-profile-view/${companyId}`)
  }

  const toggleFavorite = async (event, companyId) => {
    event.stopPropagation() // Prevent the card click event from firing
    const userId = auth.currentUser?.uid
    if (!userId) return

    const updatedFavorites = favorites.includes(companyId)
      ? favorites.filter((id) => id !== companyId)
      : [...favorites, companyId]

    setFavorites(updatedFavorites)
    await updateJobseekerProfile(userId, { favorites: updatedFavorites })
  }
  // Helper function to get employee range
  const getEmployeeRange = (count) => {
    if (!count) return null
    const numCount = parseInt(count)
    if (isNaN(numCount)) return null

    if (numCount <= 10) return '1-10'
    if (numCount <= 50) return '11-50'
    if (numCount <= 200) return '51-200'
    if (numCount <= 500) return '201-500'
    return '500+'
  }

  // Update getFilterCount to handle arrays
  const getFilterCount = (filterType, value) => {
    return companies.filter((company) => {
      switch (filterType) {
        case 'industry':
          return company.industries && company.industries.includes(value)
        case 'subcategory':
          return company.subIndustries && company.subIndustries.includes(value)
        case 'employeeCount':
          return company.employeeRange === value
        case 'workPolicy':
          return company.workPolicy === value
        default:
          return false
      }
    }).length
  }

  const toggleFilter = (filterName) => {
    setOpenFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName]
    }))
  }

  const clearFilters = () => {
    setSelectedIndustries([])
    setSelectedSubcategories([])
    setSelectedEmployeeCount('')
    setSelectedWorkPolicy('')
  }

  const handleIndustryChange = (industry) => {
    setSelectedIndustries((prev) => {
      const newIndustries = prev.includes(industry)
        ? prev.filter((i) => i !== industry)
        : [...prev, industry]

      // Remove subcategories that don't belong to selected industries
      setSelectedSubcategories((prev) =>
        prev.filter((sub) =>
          newIndustries.some((ind) => allSubcategories[ind]?.includes(sub))
        )
      )

      return newIndustries
    })
  }

  const fetchCompanies = async () => {
    try {
      setLoading(true)
      const employersData = await getEmployers()

      const companiesWithProfiles = await Promise.all(
        employersData.map(async (employer) => {
          try {
            const profile = await getEmployerProfile(employer.id)

            // Handle array values for industry and work policy
            const industries = Array.isArray(profile?.industry)
              ? profile.industry
              : profile?.industry
              ? [profile.industry]
              : []

            const subIndustries = Array.isArray(profile?.sub_industry)
              ? profile.sub_industry
              : profile?.sub_industry
              ? [profile.sub_industry]
              : []

            // Handle work policy exactly as it appears in the database
            const workPolicy = profile?.work_policy || 'Not specified'

            return {
              id: employer.id,
              name: employer.name || profile?.company_name || 'Unnamed Company',
              logo: profile?.company_logo_url || null,
              description:
                profile?.company_description ||
                employer.website ||
                'No description available',
              location: profile?.location || employer.location || '',
              jobCount: profile?.number_of_open_jobs || employer.openRoles || 0,
              isArenaPartner: profile?.plan_type === 'partner' || false,
              isHiring:
                profile?.number_of_open_jobs > 0 || employer.openRoles > 0,
              workPolicy: workPolicy,
              website: profile?.company_website || profile?.website || null,
              industries: industries,
              subIndustries: subIndustries,
              employeeCount: profile?.full_time_employees || null,
              employeeRange: getEmployeeRange(profile?.full_time_employees),
              // Store raw data for debugging
              rawProfile: profile
            }
          } catch (error) {
            console.error(`Error processing employer ${employer.id}:`, error)
            return {
              id: employer.id,
              name: employer.name || 'Unnamed Company',
              description: employer.website || 'No description available',
              location: employer.location || '',
              jobCount: employer.openRoles || 0,
              isHiring: employer.openRoles > 0,
              industries: [],
              subIndustries: [],
              workPolicy: 'Not specified'
            }
          }
        })
      )
      const validCompanies = companiesWithProfiles.filter(Boolean)

      setCompanies(validCompanies)
      setFilteredCompanies(validCompanies)
      setError(null)
    } catch (err) {
      console.error('Error fetching companies:', err)
      setError('Failed to load companies. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  // Update filterCompanies to handle work policy
  const filterCompanies = () => {
    console.log('Filtering with criteria:', {
      selectedIndustries,
      selectedSubcategories,
      selectedWorkPolicy,
      searchTerm
    })

    let filtered = companies.filter((company) => {
      // Only Include favorites if View Favorites Toggle is active
      if (viewFavorites && !favorites.includes(company.id)) {
        return false
      }
      // Search term matching
      const matchesSearch =
        !searchTerm ||
        (company.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (company.description || '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())

      // Industry matching
      const matchesIndustry =
        selectedIndustries.length === 0 ||
        (company.industries &&
          selectedIndustries.some((selected) =>
            company.industries.includes(selected)
          ))

      // Subcategory matching
      const matchesSubcategory =
        selectedSubcategories.length === 0 ||
        (company.subIndustries &&
          selectedSubcategories.some((selected) =>
            company.subIndustries.includes(selected)
          ))

      // Work policy matching
      const matchesWorkPolicy =
        !selectedWorkPolicy || company.workPolicy === selectedWorkPolicy

      const matches =
        matchesSearch &&
        matchesIndustry &&
        matchesSubcategory &&
        matchesWorkPolicy

      // Debug logging for non-matching companies
      if (!matches) {
        console.log('Company filtered out:', company.name, {
          workPolicy: company.workPolicy,
          selectedWorkPolicy,
          matchesWorkPolicy,
          matchesIndustry,
          matchesSubcategory,
          matchesSearch
        })
      }

      return matches
    })

    // Sort Dropdown Options
    if (selectedSort === 'recent') {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    } else if (selectedSort === 'alphabetical') {
      filtered.sort((a, b) => a.name.localeCompare(b.name))
    } else if (selectedSort === 'reverse-alphabetical') {
      filtered.sort((a, b) => b.name.localeCompare(a.name))
    }

    setFilteredCompanies(filtered)
  }

  const CompanyCardContent = ({ company }) => {
    const handleCardClick = () => {
      navigate(`/employer-profile-view/${company.id}`)
    }

    return (
      <CompanyCard onClick={handleCardClick}>
        <CompanyInfo>
          {company.logo ? (
            <CompanyLogo src={company.logo} alt={`${company.name} logo`} />
          ) : (
            <LogoPlaceholder>No Logo</LogoPlaceholder>
          )}
          <CompanyDetails>
            <CompanyName>{company.name}</CompanyName>
            <JobCount>
              {company.jobCount || 0} Jobs {company.location || ''}
            </JobCount>
            <TagList>
              {company.industries &&
                company.industries.map((industry, index) => (
                  <Tag key={`industry-${index}`}>Industry: {industry}</Tag>
                ))}
              {company.subIndustries &&
                company.subIndustries.map((subIndustry, index) => (
                  <Tag key={`subindustry-${index}`}>Type: {subIndustry}</Tag>
                ))}
              {company.workPolicy && company.workPolicy !== 'Not specified' && (
                <Tag style={{ backgroundColor: '#E9D8FD', color: '#553C9A' }}>
                  {company.workPolicy}
                </Tag>
              )}
            </TagList>
          </CompanyDetails>
        </CompanyInfo>
        <HeartIcon onClick={(e) => toggleFavorite(e, company.id)}>
          <Heart
            size={24}
            fill={favorites.includes(company.id) ? '#caaae1' : 'none'}
            color={favorites.includes(company.id) ? '#caaae1' : '#718096'}
          />
        </HeartIcon>
      </CompanyCard>
    )
  }
  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>

      <MainContent>
        {profile?.plan === 'paid' && (
          <ProBanner>
            <ProText>Arena Pro✨</ProText>
          </ProBanner>
        )}
        {profile?.plan !== 'paid' && (
          <UpgradeButton
            href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
            target="_blank"
            rel="noopener noreferrer"
            >Upgrade to Arena Pro✨
          </UpgradeButton>
        )}
        <Header>
          <Title>🏢 Search Companies</Title>
        </Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search by company name or keyword"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <SearchButton onClick={filterCompanies}>
            <Search size={18} />
            <SearchButtonText>Search</SearchButtonText>
          </SearchButton>
        </SearchBar>

        {loading ? (
          <LoadingMessage>Loading companies...</LoadingMessage>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <ContentWrapper>
            <Sidebar>
              <FilterHeader>
                <h3>Filters</h3>
                {(selectedIndustries.length > 0 ||
                  selectedSubcategories.length > 0 ||
                  selectedEmployeeCount ||
                  selectedWorkPolicy) && (
                  <ClearFilterButton onClick={clearFilters}>
                    Clear all
                  </ClearFilterButton>
                )}
              </FilterHeader>

              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('industry')}>
                  Industry
                  {openFilters.industry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                {openFilters.industry &&
                  industries.map((industry) => (
                    <div key={industry}>
                      <CheckboxLabel>
                        <input
                          type="checkbox"
                          checked={selectedIndustries.includes(industry)}
                          onChange={() => handleIndustryChange(industry)}
                        />
                        {industry}
                        <FilterCount>
                          ({getFilterCount('industry', industry)})
                        </FilterCount>
                      </CheckboxLabel>

                      {selectedIndustries.includes(industry) && (
                        <SubcategorySection>
                          {allSubcategories[industry]?.map((subcategory) => (
                            <CheckboxLabel key={subcategory}>
                              <input
                                type="checkbox"
                                checked={selectedSubcategories.includes(
                                  subcategory
                                )}
                                onChange={() => {
                                  setSelectedSubcategories((prev) =>
                                    prev.includes(subcategory)
                                      ? prev.filter((s) => s !== subcategory)
                                      : [...prev, subcategory]
                                  )
                                }}
                              />
                              {subcategory}
                              <FilterCount>
                                ({getFilterCount('subcategory', subcategory)})
                              </FilterCount>
                            </CheckboxLabel>
                          ))}
                        </SubcategorySection>
                      )}
                    </div>
                  ))}
              </FilterSection>

              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('employeeCount')}>
                  Company Size
                  {openFilters.employeeCount ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                {openFilters.employeeCount &&
                  employeeRanges.map((range) => (
                    <RadioLabel key={range.label}>
                      <input
                        type="radio"
                        name="employeeCount"
                        checked={selectedEmployeeCount === range.label}
                        onChange={() => setSelectedEmployeeCount(range.label)}
                      />
                      {range.label}
                      <FilterCount>
                        ({getFilterCount('employeeCount', range.label)})
                      </FilterCount>
                    </RadioLabel>
                  ))}
              </FilterSection>

              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('workPolicy')}>
                  Work Policy
                  {openFilters.workPolicy ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.workPolicy}>
                  {workPolicyOptions.map((policy) => (
                    <RadioLabel key={policy}>
                      <input
                        type="radio"
                        name="workPolicy"
                        checked={selectedWorkPolicy === policy}
                        onChange={() => setSelectedWorkPolicy(policy)}
                      />
                      {policy}
                      <FilterCount>
                        ({getFilterCount('workPolicy', policy)})
                      </FilterCount>
                    </RadioLabel>
                  ))}
                  {selectedWorkPolicy && (
                    <Button
                      onClick={() => setSelectedWorkPolicy('')}
                      style={{ marginTop: '0.5rem', fontSize: '0.8rem' }}
                    >
                      Clear Work Policy Filter
                    </Button>
                  )}
                </FilterContent>
                <SaveFilterButton onClick={() => setIsModalOpen(true)}>
                  <Heart size={18} />
                  Save Filter
                </SaveFilterButton>

                {/* Make Saved Filters section collapsible */}
                {savedFilters.length > 0 && (
                  <SavedFiltersList>
                    <FilterTitle onClick={() => toggleFilter('savedFilters')}>
                      Saved Filters ({savedFilters.length})
                      {openFilters.savedFilters ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <SavedFiltersContent isOpen={openFilters.savedFilters}>
                      {savedFilters.map((filter) => (
                        <SavedFilterItem key={filter.id}>
                          <span
                            onClick={() => applyFilter(filter)}
                            style={{
                              flex: 1,
                              fontSize: '0.875rem',
                              color: '#4A5568'
                            }}
                          >
                            {filter.name}
                          </span>
                          <DeleteButton
                            onClick={(e) => {
                              e.stopPropagation()
                              deleteFilter(filter.id)
                            }}
                            title="Delete filter"
                          >
                            <X size={16} />
                          </DeleteButton>
                        </SavedFilterItem>
                      ))}
                    </SavedFiltersContent>
                  </SavedFiltersList>
                )}
              </FilterSection>
            </Sidebar>

            <CompanyList>
              <CompanyListHeader>
                  <TabSwitch 
                    viewFavorites={viewFavorites}
                    setViewFavorites={setViewFavorites}
                    companies={companies}
                    favorites={favorites}
                  />

                <SortSelect onChange={(e) => setSelectedSort(e.target.value)}>
                  <option value="recent">Sort by Most Recent</option>
                  <option value="alphabetical">Sort A-Z</option>
                  <option value="reverse-alphabetical">Sort Z-A</option>
                </SortSelect>
              </CompanyListHeader>
              <JobCount>{filteredCompanies.length} results</JobCount>

              {filteredCompanies.map((company) => (
                <CompanyCard key={company.id}>
                  {company.isArenaPartner && (
                    <PartnerBadge>Arena Partner</PartnerBadge>
                  )}
                  <CompanyCardContent company={company} />
                </CompanyCard>
              ))}
            </CompanyList>
          </ContentWrapper>
        )}
        {isModalOpen && (
          <ModalOverlay>
            <ModalContent>
              <ModalClose
                onClick={() => {
                  setIsModalOpen(false)
                  setFilterName('')
                  setError(null)
                }}
              >
                <X size={20} />
              </ModalClose>
              <ModalTitle>Save Current Filter</ModalTitle>
              <SearchInput
                type="text"
                placeholder="Enter a name for your filter"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <SaveFilterButton onClick={saveCurrentFilter}>
                Save Filter
              </SaveFilterButton>
            </ModalContent>
          </ModalOverlay>
        )}
      </MainContent>
    </PageWrapper>
  )
}
