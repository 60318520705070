import React, { useState } from 'react'
import styled from 'styled-components'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../firebase'
import { X } from 'lucide-react'

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  backdrop-filter: blur(4px);
`

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #718096;
  transition: color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #2d3748;
  }
`

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
`

const Input = styled.input`
  width: 95%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  color: #2d3748;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #8b5cf6;
    box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.1);
  }

  &::placeholder {
    color: #a0aec0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s;
  font-size: 0.875rem;
  cursor: pointer;

  ${(props) =>
    props.primary
      ? `
    background-color: #8b5cf6;
    color: white;
    border: none;

    &:hover {
      background-color: #7c3aed;
    }

    &:disabled {
      background-color: #c4b5fd;
      cursor: not-allowed;
    }
  `
      : `
    background-color: transparent;
    color: #4b5563;
    border: 1px solid #e5e7eb;

    &:hover {
      background-color: #f3f4f6;
    }

    &:disabled {
      color: #9ca3af;
      cursor: not-allowed;
    }
  `}
`

const ErrorMessage = styled.div`
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: #fee2e2;
  border-radius: 0.375rem;
`

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 0.5rem;
`

const StepDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#8b5cf6' : '#e2e8f0')};
  transition: background-color 0.3s ease;
`

const SuccessContainer = styled.div`
  text-align: center;
  padding: 1rem;
`

const SuccessTitle = styled.h3`
  color: #059669;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

const SuccessMessage = styled.div`
  color: #374151;
  margin-bottom: 1.5rem;
  line-height: 1.5;

  p {
    margin: 0.75rem 0;
  }
`

const PasswordBox = styled.div`
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1.5rem 0;
  font-family: monospace;
  font-size: 1.125rem;
  color: #4b5563;
  border: 1px dashed #d1d5db;
  user-select: all;
`

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f3f4f6;
  border-radius: 50%;
  border-top-color: #8b5cf6;
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

const PasswordPreview = styled.div`
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-top: 1rem;
  font-size: 1.25rem;
  color: #4b5563;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PasswordInfo = styled.div`
  font-family: 'Inter';
`

const CopyButton = styled.button`
  background: none;
  border: none;
  color: #8b5cf6;
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  transition: all 0.2s;

  &:hover {
    background-color: #f3f4f6;
  }
`

const AddTeamMemberModal = ({ isOpen, onClose, currentUser }) => {
  const [step, setStep] = useState(1)
  const [copyText, setCopyText] = useState('Copy')

  const [formData, setFormData] = useState({
    code: '',
    firstName: '',
    lastName: '',
    email: ''
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    })
    setError('')
  }

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
      setCopyText('Copied!')
      // Reset back to "Copy" after 2 seconds
      setTimeout(() => {
        setCopyText('Copy')
      }, 4000)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  const handleCodeSubmit = (e) => {
    e.preventDefault()
    if (formData.code === 'ArenaPartner2025') {
      setStep(2)
      setError('')
    } else {
      setError('Invalid partner code. Please try again.')
    }
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email)
  }
  const handleFinalSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    // Store original user credentials
    const originalUser = auth.currentUser

    try {
      // Get current user's data from main user document
      const currentUserDoc = await getDoc(doc(db, 'users', originalUser.uid))
      if (!currentUserDoc.exists()) {
        throw new Error('Current user data not found')
      }
      const currentUserData = currentUserDoc.data()

      // Get employer profile data
      const employerProfileDoc = await getDoc(
        doc(db, 'users', originalUser.uid, 'profiles', 'employer')
      )

      if (!employerProfileDoc.exists()) {
        throw new Error('Employer profile not found')
      }
      const employerProfileData = employerProfileDoc.data()

      // Create new user in Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        'WelcomeToArena25'
      )

      // Prepare main user document data
      const userData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        company_address: currentUserData.company_address || '',
        company_name: currentUserData.company_name || '',
        company_phone: currentUserData.company_phone || '',
        employer_id: currentUserData.employer_id || '',
        plan_selected: currentUserData.plan_selected || '',
        role: currentUserData.role || '',
        subscription: currentUserData.subscription || {},
        website: currentUserData.website || '',
        created_at: new Date(),
        last_login: new Date()
      }

      // Create the main user document
      await setDoc(doc(db, 'users', userCredential.user.uid), userData)

      // Prepare employer profile data - copy everything but reset specific fields
      const newEmployerProfileData = {
        additional_url: employerProfileData.additional_url || '',
        candidate_qualities: employerProfileData.candidate_qualities || [],
        candidate_qualities_other:
          employerProfileData.candidate_qualities_other || '',
        company_address: employerProfileData.company_address || '',
        company_description: employerProfileData.company_description || '',
        company_email: employerProfileData.company_email || '',
        company_facebook: employerProfileData.company_facebook || '',
        company_linkedin: employerProfileData.company_linkedin || '',
        company_logo_url: employerProfileData.company_logo_url || '',
        company_name: employerProfileData.company_name || '',
        company_phone: employerProfileData.company_phone || '',
        company_website: employerProfileData.company_website || '',
        core_values: employerProfileData.core_values || [],
        created_at: new Date(),
        dashboard_tutorial_completed: false,
        employer_id: employerProfileData.employer_id || '',
        employer_profile_tutorial_completed: false,
        expected_full_time_hires:
          employerProfileData.expected_full_time_hires || '',
        expected_part_time_hires:
          employerProfileData.expected_part_time_hires || '',
        full_time_employees: employerProfileData.full_time_employees || '',
        hiring_challenges: employerProfileData.hiring_challenges || [],
        hiring_challenges_other:
          employerProfileData.hiring_challenges_other || '',
        hiring_goals: employerProfileData.hiring_goals || {},
        industry: employerProfileData.industry || [],
        intake_completed: employerProfileData.intake_completed || false,
        other_hiring_goals: employerProfileData.other_hiring_goals || '',
        plan_type: employerProfileData.plan_type || 'freetrial',
        sourcing_tutorial_completed: false,
        sub_industry: employerProfileData.sub_industry || [],
        updated_at: new Date(),
        videoUrl: employerProfileData.videoUrl || null,
        work_from_home_policy: employerProfileData.work_from_home_policy || ''
      }

      // Create the employer profile
      await setDoc(
        doc(db, 'users', userCredential.user.uid, 'profiles', 'employer'),
        newEmployerProfileData
      )

      // Sign back in as original user
      await auth.updateCurrentUser(originalUser)

      setLoading(false)
      setStep(3)
    } catch (error) {
      console.error('Error adding team member:', error)
      // Sign back in as original user in case of error too
      await auth.updateCurrentUser(originalUser)

      if (error.code === 'auth/email-already-in-use') {
        setError(
          'This email is already registered. Please use a different email.'
        )
      } else {
        setError(
          'An error occurred while adding the team member. Please try again.'
        )
      }
      setLoading(false)
    }
  }

  const handleClose = () => {
    setStep(1)
    setFormData({
      code: '',
      firstName: '',
      lastName: '',
      email: ''
    })
    setError('')
    onClose()
  }

  if (!isOpen) return null

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>Add Team Member</ModalTitle>
          <CloseButton onClick={handleClose} type="button" aria-label="Close">
            <X size={20} />
          </CloseButton>
        </ModalHeader>

        {/* <StepIndicator>
          <StepDot active={step === 1} />
          <StepDot active={step === 2} />
          <StepDot active={step === 3} />
        </StepIndicator> */}

        {step === 1 && (
          <form onSubmit={handleCodeSubmit}>
            <FormGroup>
              <Label htmlFor="code">Please enter partner code</Label>
              <Input
                type="text"
                id="code"
                name="code"
                value={formData.code}
                onChange={handleChange}
                required
                autoComplete="off"
                placeholder="Enter your code"
                autoFocus
              />
            </FormGroup>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <ButtonContainer>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" primary>
                Next
              </Button>
            </ButtonContainer>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleFinalSubmit}>
            <FormGroup>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                placeholder="Enter first name"
                autoFocus
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                placeholder="Enter last name"
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter email address"
              />
            </FormGroup>

            <PasswordPreview>
              <PasswordInfo>
                User's temporary password will be: WelcomeToArena25
              </PasswordInfo>
              <CopyButton
                type="button"
                onClick={() => copyToClipboard('WelcomeToArena25')}
              >
                {copyText}
              </CopyButton>
            </PasswordPreview>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <ButtonContainer>
              <Button
                type="button"
                onClick={() => setStep(1)}
                disabled={loading}
              >
                Back
              </Button>
              <Button type="submit" primary disabled={loading}>
                {loading ? (
                  <>
                    <LoadingSpinner /> Adding...
                  </>
                ) : (
                  'Add Team Member'
                )}
              </Button>
            </ButtonContainer>
          </form>
        )}

        {step === 3 && (
          <SuccessContainer>
            <SuccessTitle>Team Member Added Successfully!</SuccessTitle>
            <SuccessMessage>
              <p>
                An account has been created for {formData.firstName}{' '}
                {formData.lastName}.
              </p>
              <p>Please share the following temporary password with them:</p>
              <PasswordBox>WelcomeToArena25</PasswordBox>
              <p>
                They will be prompted to change this password upon their first
                login.
              </p>
            </SuccessMessage>
            <ButtonContainer style={{ justifyContent: 'center' }}>
              <Button type="button" primary onClick={handleClose}>
                Done
              </Button>
            </ButtonContainer>
          </SuccessContainer>
        )}
      </ModalContainer>
    </ModalOverlay>
  )
}

export default AddTeamMemberModal
