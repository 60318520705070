import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db, auth } from '../../firebase'
import Match from '../Match'
import MatchAdzuna from '../MatchAdzuna'
import { useLocation } from "react-router-dom";

import {
  Search,
  Briefcase,
  MapPin,
  Building,
  Clock,
  X,
  Heart,
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  Shield,
  ChevronDown,
  ChevronUp,
  Lock,
  Ellipsis,
  ArrowLeft,
  ArrowRight,
  HelpingHandIcon,
  Clapperboard,
  PartyPopper,
  Gift,
  Shirt,
  Ticket,
  Plane,
} from 'lucide-react'
import { getEmployerProfile } from '../../models/EmployerProfile'
import { BanknoteIcon } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { useNavigate } from 'react-router-dom'
import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: -1rem;
  }
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
`

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const SearchInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #4a5568;
  margin-right: 8px;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2d3748;
  }
`

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #caaae1;

  &:hover {
    background-color: #3182ce;
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`
const SearchButtonText = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 275px; 
  max-width: 275px;
  height: fit-content;
  position: sticky;
  top: 2rem;
  @media (max-width: 768px) {
    width: auto;
  }
`

const FilterCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 10px;
`

const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #1a202c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease;

  &:hover {
    color: #caaae1;
  }

  svg {
    transition: transform 0.2s ease;
  }
`

const FilterSection = styled.div`
  margin-bottom: 16px;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  border-bottom: 1px solid #e2e8f0;
`

const FilterContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  max-height: 300px;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
`

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 8px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #94a3b8;
  }
`

const Checkbox = styled.input`
  margin-right: 8px;
  appearance: none;
  min-width: 16px;
  min-height: 16px;
  border: 2px solid #caaae1;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #caaae1;
  }

  &:checked::after {
    content: '✓';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.5);
  }
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7f7f7;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const CheckboxLabel = styled(RadioLabel)`
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const ClearButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    background-color: #b68fd8;
  }
`

const JobList = styled.div`
  flex-grow: 1;
`

const JobListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

const JobCard = styled.div`
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1.5rem;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const JobCardContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  cursor: pointer;
  @media (max-width: 1024px) {
    margin-top: 2rem;
  }
`

const CompanyLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: contain;
  background-color: #f8f9fa;
`

const JobInfo = styled.div`
  flex: 1;
`

const JobTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
  margin: 0 0 8px 0;
  @media (min-width: 1024px) {
    max-width: calc(100% - 100px);
  }
`

const CompanyName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4a5568;
  margin-bottom: 12px;
`

const JobMetadata = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 14px;
  color: #4a5568;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`
const PulseAnimation = styled.div`
  animation: pulse 2s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: #f1f5f9;
    }
    50% {
      background-color: #f7fafc;
    }
    100% {
      background-color: #f1f5f9;
    }
  }
`
const SkeletonCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
`

const SkeletonContent = styled.div`
  display: flex;
  gap: 16px;
`

const SkeletonImage = styled(PulseAnimation)`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: #f1f5f9;
`

const SkeletonText = styled.div`
  flex: 1;

  div {
    height: ${(props) => props.height || '20px'};
    width: ${(props) => props.width || '100%'};
    background: #f1f5f9;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: pulse 2s infinite ease-in-out;
    ${PulseAnimation}
  }
`

const JobCardWrapper = styled.div`
  position: relative; // Added to establish positioning context

  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 16px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const MatchBubble = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #22c55e;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  z-index: 1; // Added to ensure bubble appears above other content

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`

const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  background-color: #1f2937;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  transition: all 0.2s ease;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 16px;
    border-width: 6px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
  }
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 400px;
  position: relative;
`

const ModalClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;

  &:hover {
    color: #2d3748;
  }
`
const ClearFilterButton = styled.button`
  color: #4299e1;
  font-size: 0.875rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`

const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2d3748;
`

const SaveFilterButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin: 1rem 0;
  background-color: #caaae1;

  &:hover {
    background-color: #b794f4;
  }
`

const SavedFilterSection = styled(FilterSection)`
  margin-bottom: 1rem;
`

const SavedFilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  background-color: #f7fafc;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #edf2f7;
  }
`

const Disclaimer = styled.div`
  background-color: #f9fafb;
  border: 1px solid #e2e8f0;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const DeleteButton = styled.button`
  color: #e53e3e;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: #fed7d7;
  }
`

const LogoPlaceholder = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
  border-radius: 8px;
  color: #a0aec0;
  font-size: 0.875rem;
`

const employmentTypes = [
  'Full-Time',
  'Part-time',
  'Internship',
  'Contract',
  'Hourly'
]

const levelOptions = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'Esports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}

const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: absolute;
  margin-top: 10px;
  top: 0;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const ProIcon = styled.img`
  width: 80px;
  height: 80px;
  cursor: pointer;
`
const TooltipText = styled.div`
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  white-space: normal;
  min-width: 200px;
  max-width: 300px;
  word-wrap: break-word;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }

  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  .button {
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    color: #1a202c;
    padding: 4px 8px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #edf2f7;
    }
  }
`
const UpgradeButton = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #d29856;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  z-index: 1001; // Added to ensure bubble appears above other content
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
  }
`
const PageButton = styled.button`
  border: none;
  border-radius: 8px;
  font-weight: 400;

  width: 2rem;
  aspect-ratio: 1;
  background-color: #f8f9fa;
  transition: all 0.2s ease;

  &:hover {
    color: white;
    background-color: #caaae1;
  }
`
const PageNavigation = styled.div`
  margin-top: 2rem;
  display: flex;
  align-self: center;
  gap: 0.5rem;
  justify-content: center;
`

const workPolicyOptions = ['Remote', 'In-Office', 'Hybrid']

const benefitCategories = [
  { value: 'Bonus/Stipends', label: 'Bonus/Stipends', icon: BanknoteIcon },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Discounts', label: 'Discounts', icon: BanknoteIcon},
  { value: 'Entertainment', label: 'Entertainment', icon: Clapperboard},
  {
    value: 'Employee Resource Groups',
    label: 'Employee Resource Groups',
    icon: HelpingHandIcon
  },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Equity', label: 'Equity', icon: BanknoteIcon },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Holidays', label: 'Holidays', icon: PartyPopper },
  { value: 'Insurance', label: 'Insurance', icon: Shield },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Miscellaneous ', label: 'Miscellaneous ', icon: Gift },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Swag', label: 'Swag', icon: Shirt },
  { value: 'Tickets', label: 'Tickets', icon: Ticket },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  { value: 'Travel', label: 'Travel', icon: Plane},
  { value: 'Wellness', label: 'Wellness', icon: Smile },
]

const ITEMS_PER_PAGE = 25

const CollapsibleFilterSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation() // use for filter states being passed

  // if company filter is passed in then start with that filter open
  useEffect(() => {
    if (title === "Companies" && location.state?.companyName) {
      setIsOpen(true)
    }
  }, [title, location.state])

  return (
    <FilterSection>
      <FilterTitle onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </FilterTitle>
      <FilterContent isOpen={isOpen}>{children}</FilterContent>
    </FilterSection>
  )
}

const SearchableCheckboxList = ({
  options = [], // Add default value
  selectedOptions = [], // Add default value
  onChange,
  name,
  hideSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  // Filter out null/undefined values and ensure all values are strings
  const validOptions = (options || [])
    .filter((option) => option != null)
    .map((option) => String(option))

  const filteredOptions = validOptions.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <>
      {!hideSearch && ( // hide search bar conditionally
        <Input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {filteredOptions.map((option) => (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            name={name}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={onChange}
          />
          {option}
        </CheckboxLabel>
      ))}
    </>
  )
}

const JobSearch = () => {
  const [jobPostings, setJobPostings] = useState([])
  const [showFavoritedCompaniesOnly, setShowFavoritedCompaniesOnly] =
    useState(false)

  const [loading, setLoading] = useState(true)
  const [userProfile, setUserProfile] = useState(null)
  const navigate = useNavigate()

  const FILTER_STORAGE_KEY = 'jobFilters'

  const getStoredFilters = () => {
    const savedFilters = sessionStorage.getItem(FILTER_STORAGE_KEY)
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          searchTerm: '',
          companies: [],
          titles: [],
          recommendedSkills: [],
          locations: [],
          workPolicies: [],
          departments: [],
          levels: [],
          types: [],
          salaryMin: '',
          salaryMax: '',
          willingToSponsor: false,
          benefits: []
        }
  }

  const [filters, setFilters] = useState(getStoredFilters)

  useEffect(() => {
    sessionStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filters))
  }, [filters])

  const [filteredJobs, setFilteredJobs] = useState([])
  const [numJobs, setNumJobs] = useState(0)

  const [jobSeekerProfile, setJobSeekerProfile] = useState(null)
  const getMatchColor = (matchPercentage) => {
    if (matchPercentage < 40) return '#e53e3e' // Red
    if (matchPercentage < 60) return '#ed8936' // Orange
    if (matchPercentage < 80) return '#ecc94b' // Yellow
    return '#22c55e' // Green
  }
  const [filterOptions, setFilterOptions] = useState({
    companies: [],
    titles: [],
    recommendedSkills: [],
    locations: [],
    departments: []
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterName, setFilterName] = useState('')
  const [savedFilters, setSavedFilters] = useState([])
  const [modalError, setModalError] = useState(null)
  const [sortByDate, setSortByDate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1) // Pagination state

  const totalPages = Math.ceil(filteredJobs.length / ITEMS_PER_PAGE)
  const location = useLocation() 

  // Paginated jobs
  const paginatedJobs = filteredJobs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const goToPage = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    window.scrollTo({ top: '10%', behavior: 'smooth' })
  }, [currentPage])

  // if company state is passed in apply company filter
  useEffect(() => {
    if (location.state?.companyName) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        companies: [location.state.companyName],
      }))
    }
  }, [location.state])

  const renderPageNumbers = () => {
    const pages = []
    const delta = 2 // Number of pages to show around the current page

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= currentPage - delta && i <= currentPage + delta) // Pages near the current page
      ) {
        pages.push(i)
      }
    }

    const pageButtons = []
    let previousPage = 0

    for (const page of pages) {
      if (page - previousPage > 1) {
        pageButtons.push(<Ellipsis key={`ellipsis-${page}`} />)
      }

      pageButtons.push(
        <PageButton
          key={page}
          onClick={() => goToPage(page)}
          disabled={page === currentPage}
          isActive={page === currentPage}
        >
          {page}
        </PageButton>
      )

      previousPage = page
    }

    return pageButtons
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        try {
          const profile = await getJobseekerProfile(userId)
          setUserProfile(profile)
        } catch (error) {
          console.error('Error fetching user profile:', error)
        }
      }
    }

    fetchUserProfile()
  }, [])

  useEffect(() => {
    const userId = auth.currentUser?.uid
    if (userId) {
      fetchSavedFilters(userId)
    }
  }, [])

  // Add function to fetch saved filters
  const fetchSavedFilters = async (userId) => {
    try {
      const profile = await getJobseekerProfile(userId)
      setSavedFilters(profile?.saved_job_filters || [])
    } catch (error) {
      console.error('Error fetching saved filters:', error)
      setSavedFilters([])
    }
  }

  // Add function to save current filter
  const saveCurrentFilter = async () => {
    if (!filterName.trim()) {
      setModalError('Please enter a name for your filter')
      return
    }

    const userId = auth.currentUser?.uid
    if (!userId) return

    const newFilter = {
      id: Date.now().toString(),
      name: filterName.trim(),
      configuration: {
        ...filters
      }
    }

    try {
      const updatedFilters = [...savedFilters, newFilter]
      await updateJobseekerProfile(userId, {
        saved_job_filters: updatedFilters
      })
      setSavedFilters(updatedFilters)
      setIsModalOpen(false)
      setFilterName('')
      setModalError(null)
    } catch (error) {
      console.error('Error saving filter:', error)
      setModalError('Failed to save filter. Please try again.')
    }
  }

  // Add function to delete saved filter
  const deleteFilter = async (filterId, event) => {
    if (event) {
      event.stopPropagation()
    }
    const userId = auth.currentUser?.uid
    if (!userId) return

    try {
      const updatedFilters = savedFilters.filter(
        (filter) => filter.id !== filterId
      )
      await updateJobseekerProfile(userId, {
        saved_job_filters: updatedFilters
      })
      setSavedFilters(updatedFilters)
    } catch (error) {
      console.error('Error deleting filter:', error)
    }
  }

  // Add function to apply saved filter
  const applyFilter = (filter) => {
    setFilters(filter.configuration)
  }

  useEffect(() => {
    const fetchEmployersAndJobs = async () => {
      try {
        setLoading(true)

        // Fetch employers
        const employersQuery = query(
          collection(db, 'users'),
          where('role', '==', 'employer')
        )
        const employersSnapshot = await getDocs(employersQuery)

        // Build employer profiles
        const employerProfiles = {}
        for (const employerDoc of employersSnapshot.docs) {
          const userData = employerDoc.data()
          try {
            const profileData = await getEmployerProfile(employerDoc.id)
            if (profileData) {
              const employerId = profileData.employer_id || userData.employer_id
              if (employerId) {
                employerProfiles[employerId] = {
                  company_name:
                    profileData.company_name || userData.company_name,
                  company_logo_url:
                    profileData.company_logo_url || '/default-company-logo.png',
                  company_website: profileData.company_website,
                  industry: profileData.industry,
                  subindustry: profileData.subindustry
                }
              }
            } else if (userData.employer_id) {
              employerProfiles[userData.employer_id] = {
                company_name: userData.company_name,
                company_logo_url: '/default-company-logo.png',
                industry: null,
                subindustry: null
              }
            }
          } catch (error) {
            if (userData.employer_id) {
              employerProfiles[userData.employer_id] = {
                company_name: userData.company_name,
                company_logo_url: '/default-company-logo.png',
                industry: null,
                subindustry: null
              }
            }
          }
        }

        // Fetch internal jobs
        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('status', '==', 'active')
        )
        const internalSnapshot = await getDocs(jobsQuery)

        // Fetch external jobs
        const externalJobsQuery = query(
          collection(db, 'externalJobs'),
          where('status', '==', 'active')
        )
        const externalSnapshot = await getDocs(externalJobsQuery)

        // Process internal jobs with match scores
        const internalPostings = await Promise.all(
          internalSnapshot.docs.map(async (doc) => {
            const jobData = doc.data()
            const employerId = jobData.employer_id?.toString()

            // Fallback to default values if employerProfile data is not available
            const defaultEmployerProfile = {
              company_name: 'Arena Talent', // Default company name
              company_logo_url: '/images/employer-icon.png' // Default company logo
            }

            const employerProfile =
              employerProfiles[employerId] || defaultEmployerProfile

            // Prioritize company_name from jobData.employer_profile first
            const companyName =
              jobData.employer_profile?.company_name || // NEW: Get from job data first
              employerProfile.company_name // Fallback

            const companyLogo = employerProfile.company_logo_url

            const matchScore = await Match(
              { id: doc.id, ...jobData },
              jobSeekerProfile,
              employerProfile
            )

            return {
              id: doc.id,
              ...jobData,
              matchScore: matchScore?.totalScore || 0,
              employerProfile: {
                company_name: companyName,
                company_logo_url: companyLogo,
                company_website:
                  jobData.employer_profile?.company_website || '',
                industry: employerProfile?.industry,
                subindustry: employerProfile?.subindustry
              },
              source: 'arena'
            }
          })
        )

        // Process external jobs
        const externalPostings = externalSnapshot.docs.map((doc) => {
          const jobData = doc.data()

          // Normalize salary data
          const salary = {
            type: jobData.salary?.type || 'yearly',
            range_start: jobData.salary?.min || 0,
            range_end: jobData.salary?.max || 0
          }

          return {
            id: doc.id,
            ...jobData,
            matchScore: 0,
            employerProfile: {
              company_name: jobData.company_name || 'Unknown',
              company_logo_url: 'images/employer-icon.png',
              company_website: ''
            },
            type: [jobData.work_type?.replace('_', ' ')].filter(Boolean),
            work_policy: 'Not specified',
            department: jobData.category?.label || 'Not specified',
            level: 'Not specified',
            recommended_skills: [],
            benefits: [],
            salary: salary,
            source: 'adzuna'
          }
        })

        // Combine and sort all jobs
        const allPostings = [...internalPostings, ...externalPostings]
        const sortedPostings = allPostings.sort((a, b) => {
          if (a.source === 'adzuna' && b.source !== 'adzuna') return 1
          if (b.source === 'adzuna' && a.source !== 'adzuna') return -1
          return b.matchScore - a.matchScore
        })

        // Update states
        setJobPostings(sortedPostings)
        setFilteredJobs(sortedPostings)
        setNumJobs(sortedPostings.length)

        // Extract filter options
        // Extract filter options from all jobs
        const options = sortedPostings.reduce(
          (acc, job) => {
            acc.companies.add(job.employerProfile.company_name)
            acc.titles.add(job.title)
            if (job.recommended_skills) {
              job.recommended_skills.forEach((skill) =>
                acc.recommendedSkills.add(skill)
              )
            }
            acc.locations.add(job.location)
            if (job.department && job.source === 'arena')
              acc.departments.add(job.department)
            return acc
          },
          {
            companies: new Set(),
            titles: new Set(),
            recommendedSkills: new Set(),
            locations: new Set(),
            departments: new Set()
          }
        )
        const sortedCompanies = Array.from(options.companies)
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b))
        const sortedTitles = Array.from(options.titles)
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b))

        setFilterOptions({
          companies: sortedCompanies,
          titles: sortedTitles,
          recommendedSkills: Array.from(options.recommendedSkills),
          locations: Array.from(options.locations),
          departments: Array.from(options.departments),
          industries: industries,
          subindustries: Object.values(allSubcategories).flat()
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchEmployersAndJobs()
  }, [jobSeekerProfile])

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'
    // Convert timestamp to YYYY-MM-DD
    return new Date(timestamp.seconds * 1000).toISOString().split('T')[0]
  }

  const sortJobsByDate = (jobs) => {
    return [...jobs].sort((a, b) => {
      const dateA = new Date(a.date_posted || formatDate(a.created_at))
      const dateB = new Date(b.date_posted || formatDate(b.created_at))
      return dateB - dateA // return recent jobs first
    })
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target

    if (name === 'sortByDate') {
      setSortByDate(checked)
    } else if (name === 'favoritedCompanies') {
      setShowFavoritedCompaniesOnly(checked)
    }
  }
  useEffect(() => {
    const applyFilters = async () => {
      let filtered = [...jobPostings] // Copy the jobPostings array

      // Apply filtering logic
      if (filters.searchTerm) {
        const searchLower = filters.searchTerm.toLowerCase()
        filtered = filtered.filter(
          (job) =>
            job.title.toLowerCase().includes(searchLower) ||
            job.employerProfile?.company_name
              .toLowerCase()
              .includes(searchLower) ||
            job.description.toLowerCase().includes(searchLower)
        )
      }

      if (filters.companies.length > 0) {
        filtered = filtered.filter((job) =>
          filters.companies.includes(job.employerProfile?.company_name)
        )
      }

      if (filters.titles.length > 0) {
        filtered = filtered.filter((job) => filters.titles.includes(job.title))
      }

      if (filters.recommendedSkills.length > 0) {
        filtered = filtered.filter((job) =>
          filters.recommendedSkills.some((skill) =>
            job.recommended_skills.includes(skill)
          )
        )
      }

      if (filters.locations.length > 0) {
        filtered = filtered.filter((job) =>
          filters.locations.includes(job.location)
        )
      }

      if (filters.workPolicies.length > 0) {
        filtered = filtered.filter((job) =>
          filters.workPolicies.includes(job.work_policy)
        )
      }

      if (filters.departments.length > 0) {
        filtered = filtered.filter((job) =>
          filters.departments.includes(job.department)
        )
      }

      if (filters.levels.length > 0) {
        filtered = filtered.filter((job) => filters.levels.includes(job.level))
      }

      if (filters.types.length > 0) {
        filtered = filtered.filter((job) =>
          filters.types.some((type) => job.type.includes(type))
        )
      }

      if (filters.salaryMin) {
        filtered = filtered.filter(
          (job) => job.salary.range_start >= parseInt(filters.salaryMin)
        )
      }

      if (filters.salaryMax) {
        filtered = filtered.filter(
          (job) => job.salary.range_end <= parseInt(filters.salaryMax)
        )
      }

      if (filters.willingToSponsor) {
        filtered = filtered.filter((job) => job.willing_to_sponsor)
      }

      if (filters.benefits.length > 0) {
        filtered = filtered.filter((job) => {
          return job.source === 'arena'
            ? job.benefits &&
                filters.benefits.some((benefit) =>
                  job.benefits.includes(benefit)
                )
            : true // External jobs pass through
        })
      }

      if (filters.industries && filters.industries.length > 0) {
        filtered = filtered.filter((job) => {
          return job.source === 'arena'
            ? filters.industries.includes(job.employerProfile.industry)
            : true // External jobs pass through
        })
      }

      if (filters.subindustries && filters.subindustries.length > 0) {
        filtered = filtered.filter((job) => {
          return job.source === 'arena'
            ? filters.subindustries.includes(job.employerProfile.subindustry)
            : true // External jobs pass through
        })
      }

      if (
        showFavoritedCompaniesOnly &&
        jobSeekerProfile?.favorites?.length > 0
      ) {
        filtered = filtered.filter((job) =>
          jobSeekerProfile.favorites.includes(job.employer_id)
        )
      }

      // Calculate matchScore for each job and log it
      filtered = filtered.map((job) => {
        const matchScore =
          Match(job, jobSeekerProfile, job.employerProfile) || 0
        console.log(`Job ID: ${job.id}, Match Score: ${matchScore}`) // Debugging
        return {
          ...job,
          matchScore
        }
      })

      console.log(
        'Before Sorting:',
        filtered.map((job) => job.matchScore)
      ) // Debugging

      // Sort jobs by matchScore in descending order OR by recently posted
      if (!sortByDate) {
        filtered.sort((a, b) => Number(b.matchScore) - Number(a.matchScore))
      } else {
        filtered = sortJobsByDate(filtered)
      }

      console.log(
        'After Sorting:',
        filtered.map((job) => job.matchScore)
      ) // Debugging

      // Update the state with sorted and filtered jobs
      setFilteredJobs(filtered)
    }

    applyFilters()
  }, [
    filters,
    jobPostings,
    jobSeekerProfile,
    sortByDate,
    showFavoritedCompaniesOnly
  ])

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target
    setFilters((prev) => {
      // Handle checkbox type (for array-based filters and boolean filters)
      if (type === 'checkbox') {
        // Handle array-based filters
        if (
          name === 'companies' ||
          name === 'titles' ||
          name === 'recommendedSkills' ||
          name === 'locations' ||
          name === 'workPolicies' ||
          name === 'departments' ||
          name === 'levels' ||
          name === 'types' ||
          name === 'benefits' ||
          name === 'industries' ||
          name === 'subindustries'
        ) {
          return {
            ...prev,
            [name]: checked
              ? [...(prev[name] || []), value]
              : (prev[name] || []).filter((item) => item !== value)
          }
        } else if (name === 'willingToSponsor') {
          return { ...prev, [name]: checked }
        } else if (name === 'sortByDate') {
          return { ...prev, sortByDate: checked }
        } else if (name === 'favoritedCompanies') {
          return { ...prev, favoritedCompanies: checked }
        }
      } else {
        return { ...prev, [name]: value }
      }
    })
  }

  const clearFilters = () => {
    setFilters({
      searchTerm: '',
      companies: [],
      titles: [],
      recommendedSkills: [],
      locations: [],
      workPolicies: [],
      departments: [],
      levels: [],
      types: [],
      salaryMin: '',
      salaryMax: '',
      willingToSponsor: false,
      benefits: []
    })
    setSortByDate(false)

    setShowFavoritedCompaniesOnly(false)
  }

  const handleJobClick = (jobId, job, matchPercentage) => {
    navigate(`/job-opening/${jobId}`, {
      state: {
        companyName: job.employerProfile?.company_name,
        companyLogoUrl: job.employerProfile?.company_logo_url,
        matchScore: matchPercentage
      }
    })
  }

  useEffect(() => {
    const fetchProfile = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        const profile = await getJobseekerProfile(userId)
        setJobSeekerProfile(profile)
      }
    }

    fetchProfile()
  }, [])

  const WillingToSponsorFilter = ({ value, onChange }) => {
    return (
      <CheckboxLabel>
        <Checkbox
          type="checkbox"
          name="willingToSponsor"
          checked={value}
          onChange={onChange}
        />
        Show only jobs willing to sponsor a work visa
      </CheckboxLabel>
    )
  }

  // useEffect(() => {
  //   const fetchJobs = async () => {
  //     try {
  //       setLoading(true)

  //       // Fetch internal jobs
  //       const jobsQuery = query(
  //         collection(db, 'jobPostings'),
  //         where('status', '==', 'active')
  //       )
  //       const snapshot = await getDocs(jobsQuery)

  //       // Fetch external jobs
  //       const externalJobsQuery = query(
  //         collection(db, 'externalJobs'),
  //         where('status', '==', 'active')
  //       )
  //       const externalSnapshot = await getDocs(externalJobsQuery)

  //       // Fetch employer profiles for internal jobs
  //       const employerProfiles = {}
  //       for (const doc of snapshot.docs) {
  //         const job = doc.data()
  //         if (!employerProfiles[job.employer_id]) {
  //           try {
  //             employerProfiles[job.employer_id] = await getEmployerProfile(
  //               job.employer_id
  //             )
  //           } catch (error) {
  //             console.error(
  //               `Error fetching employer profile for ${job.employer_id}:`,
  //               error
  //             )
  //             employerProfiles[job.employer_id] = null
  //           }
  //         }
  //       }

  //       // Process internal jobs
  //       const internalJobsWithScores = await Promise.all(
  //         snapshot.docs.map(async (doc) => {
  //           const job = { id: doc.id, ...doc.data() }
  //           const employerProfile = employerProfiles[job.employer_id]
  //           const matchScore = await Match(
  //             job,
  //             jobSeekerProfile,
  //             employerProfile
  //           )

  //           return {
  //             ...job,
  //             matchScore: matchScore?.totalScore || 0,
  //             employerProfile: employerProfile || {
  //               company_name: job.company_name || 'Unknown Company',
  //               company_logo_url: '/api/placeholder/50/50'
  //             },
  //             is_external: false
  //           }
  //         })
  //       )

  //       // Process external jobs
  //       const externalJobsWithScores = await Promise.all(
  //         externalSnapshot.docs.map(async (doc) => {
  //           const externalJob = doc.data()

  //           // Transform external job to match internal job format
  //           const transformedJob = {
  //             id: doc.id,
  //             title: externalJob.title,
  //             description: externalJob.description,
  //             location: externalJob.location,
  //             type: [externalJob.work_type?.replace('_', ' ')].filter(Boolean),
  //             salary: {
  //               type: externalJob.salary?.type || 'yearly',
  //               range_start: externalJob.salary?.min || 0,
  //               range_end: externalJob.salary?.max || 0
  //             },
  //             work_policy: 'Not specified',
  //             level: 'Not specified',
  //             employerProfile: {
  //               company_name: externalJob.company_name,
  //               company_logo_url: '/images/employer-icon.png'
  //             },
  //             category: externalJob.category,
  //             created_at: externalJob.created_at,
  //             date_posted: externalJob.date_posted,
  //             external_redirect_url: externalJob.redirect_url,
  //             is_external: true
  //           }

  //           // Calculate match score for external job
  //           const matchScore = await Match(
  //             transformedJob,
  //             jobSeekerProfile,
  //             transformedJob.employerProfile
  //           )

  //           return {
  //             ...transformedJob,
  //             matchScore: matchScore?.totalScore || 0
  //           }
  //         })
  //       )

  //       // Combine and sort all jobs
  //       const allJobs = [...internalJobsWithScores, ...externalJobsWithScores]
  //       const sortedJobs = allJobs.sort((a, b) => b.matchScore - a.matchScore)

  //       setJobPostings(sortedJobs)
  //       setFilteredJobs(sortedJobs)
  //       setNumJobs(sortedJobs.length) //get total number of jobs without filters

  //       // Extract filter options from all jobs
  //       const options = sortedJobs.reduce(
  //         (acc, job) => {
  //           acc.companies.add(job.employerProfile.company_name)
  //           acc.titles.add(job.title)
  //           if (job.recommended_skills) {
  //             job.recommended_skills.forEach((skill) =>
  //               acc.recommendedSkills.add(skill)
  //             )
  //           }
  //           acc.locations.add(job.location)
  //           acc.departments.add(job.department)
  //           return acc
  //         },
  //         {
  //           companies: new Set(),
  //           titles: new Set(),
  //           recommendedSkills: new Set(),
  //           locations: new Set(),
  //           departments: new Set()
  //         }
  //       )
  //       const sortedCompanies = Array.from(options.companies)
  //         .filter(Boolean)
  //         .sort((a, b) => a.localeCompare(b))
  //       const sortedTitles = Array.from(options.titles)
  //         .filter(Boolean)
  //         .sort((a, b) => a.localeCompare(b))

  //       setFilterOptions({
  //         companies: sortedCompanies, // Use sorted companies
  //         titles: sortedTitles, // Use sorted titles
  //         recommendedSkills: Array.from(options.recommendedSkills),
  //         locations: Array.from(options.locations),
  //         departments: Array.from(options.departments)
  //       })
  //     } catch (error) {
  //       console.error('Error fetching jobs:', error)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

  //   if (jobSeekerProfile) {
  //     fetchJobs()
  //   }
  // }, [jobSeekerProfile])

  const JobCard = ({
    job,
    jobSeekerProfile,
    employerProfile,
    index,
    isPaidUser
  }) => {
    const [matchResult, setMatchResult] = useState({
      totalScore: null,
      details: []
    })
    const [feedbackState, setFeedbackState] = useState({
      like: false,
      dislike: false
    })
    const [feedbackMessage, setFeedbackMessage] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
      const calculateMatch = async () => {
        const matchScore =
          job.source === 'adzuna'
            ? MatchAdzuna(job, jobSeekerProfile)
            : Match(job, jobSeekerProfile, employerProfile)

        setMatchResult(matchScore)
      }

      calculateMatch()
    }, [job, jobSeekerProfile, employerProfile])

    const handleFeedback = (type) => {
      setFeedbackState((prev) => ({ ...prev, [type]: true }))
      setTimeout(() => {
        setFeedbackState((prev) => ({ ...prev, [type]: false }))
        setFeedbackMessage('Our AI is in Beta so thank you for your feedback!')
        setTimeout(() => setFeedbackMessage(null), 3000)
      }, 2000)
    }

    const getMatchColor = (matchPercentage) => {
      if (matchPercentage < 40) return '#e53e3e'
      if (matchPercentage < 60) return '#ed8936'
      if (matchPercentage < 80) return '#ecc94b'
      return '#22c55e'
    }

    const handleUpgradeClick = (e) => {
      e.stopPropagation()
      window.location.href = 'https://buy.stripe.com/28o03yc5z1DcdpK4gu'
    }

    const formatSalary = (salary) => {
      if (!salary) return 'Not specified'

      // Handle hidden salary type
      if (salary.type === 'hidden') return 'Undisclosed'

      // Handle unpaid salary type
      if (salary.type === 'unpaid') return 'Unpaid'

      // Format salary range
      const formatNumber = (num) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        }).format(num)

      const range = `${formatNumber(salary.range_start)} - ${formatNumber(
        salary.range_end
      )}`
      return salary.type === 'hourly' ? `${range}/hr` : `${range}/year`
    }

    if (matchResult.totalScore === null) {
      return <SkeletonCard />
    }

    const isLocked = !isPaidUser && index > 1

    return (
      <JobCardWrapper>
        {isLocked ? (
          <MatchBubble style={{ backgroundColor: '#94a3b8' }}>
            <TooltipContainer>
              <Lock size={16} />
              <TooltipText>
                <div style={{ marginBottom: '12px' }}>
                  Upgrade to Arena Pro to see all match scores and unlock
                  personalized job recommendations.
                </div>
                <button
                  onClick={handleUpgradeClick}
                  style={{
                    backgroundColor: '#caaae1',
                    color: 'white',
                    padding: '8px 16px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: '500'
                  }}
                >
                  Upgrade to Pro
                </button>
              </TooltipText>
            </TooltipContainer>
          </MatchBubble>
        ) : (
          <MatchBubble
            style={{ backgroundColor: getMatchColor(matchResult.totalScore) }}
          >
            <TooltipContainer>
              {matchResult.totalScore}% Match
              <TooltipText>
                <strong>Why This Match:</strong>
                <ul>
                  {matchResult.details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
                {feedbackMessage ? (
                  <div style={{ marginTop: '8px', color: 'white' }}>
                    {feedbackMessage}
                  </div>
                ) : (
                  <div className="actions">
                    <button
                      className="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleFeedback('like')
                      }}
                      disabled={feedbackState.like}
                    >
                      {feedbackState.like ? 'Loading...' : '👍 '}
                    </button>
                    <button
                      className="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleFeedback('dislike')
                      }}
                      disabled={feedbackState.dislike}
                    >
                      {feedbackState.dislike ? 'Loading...' : '👎'}
                    </button>
                  </div>
                )}
              </TooltipText>
            </TooltipContainer>
          </MatchBubble>
        )}
        <JobCardContent
          onClick={() => handleJobClick(job.id, job, matchResult.totalScore)}
        >
          {/* Company Logo Logic */}
          {job.employerProfile?.company_logo_url &&
          job.employerProfile.company_logo_url !==
            '/default-company-logo.png' ? (
            <CompanyLogo
              src={job.employerProfile.company_logo_url}
              alt={`${job.employerProfile?.company_name || 'Company'} logo`}
            />
          ) : (
            <CompanyLogo
              src="/images/employer-icon.png"
              alt="Default Company Logo"
            />
          )}

          {/* Job Information */}
          <JobInfo>
            <JobTitle>{job.title}</JobTitle>
            <CompanyName>
              <Building size={16} />
              <span>
                {job.employerProfile?.company_name?.toLowerCase() !==
                'unknown company'
                  ? job.employerProfile.company_name
                  : 'Arena Talent'}
              </span>
            </CompanyName>
            <JobMetadata>
              {job.location && job.location !== 'Not specified' && (
                <div>
                  <MapPin size={16} />
                  <span>{job.location}</span>
                </div>
              )}
              {job.level && job.level !== 'Not specified' && (
                <div>
                  <Briefcase size={16} />
                  <span>{job.level}</span>
                </div>
              )}
              {job.work_policy && job.work_policy !== 'Not specified' && (
                <div>
                  <Laptop size={16} />
                  <span>{job.work_policy}</span>
                </div>
              )}
              {(job.type || job.work_type) && (
                <div>
                  <Clock size={16} />
                  <span>
                    {job.type
                      ? Array.isArray(job.type)
                        ? job.type
                            .map((t) =>
                              t.toLowerCase() === 'full time' ||
                              t === 'full_time'
                                ? 'Full-Time'
                                : t
                            )
                            .join(', ')
                        : job.type.toLowerCase() === 'full time' ||
                          job.type === 'full_time'
                        ? 'Full-Time'
                        : job.type
                      : job.work_type.toLowerCase() === 'full time' ||
                        job.work_type === 'full_time'
                      ? 'Full-Time'
                      : job.work_type}
                  </span>
                </div>
              )}
              {job.salary && job.salary.min !== 0 && job.salary.max !== 0 && (
                <div>
                  <BanknoteIcon size={16} />
                  <span>{formatSalary(job.salary)}</span>
                </div>
              )}
            </JobMetadata>
          </JobInfo>
        </JobCardContent>
      </JobCardWrapper>
    )
  }

  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>

      <MainContent>
        {userProfile?.plan === 'paid' && (
          <ProBanner>
            <ProText>Arena Pro✨</ProText>
          </ProBanner>
        )}
        {userProfile?.plan !== 'paid' && (
          <UpgradeButton
            href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Arena Pro✨
          </UpgradeButton>
        )}
        <Header>
          <Title>🔍 Search Jobs</Title>
        </Header>
        {/* Disclaimer message */}

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search by title, company, or keywords"
            value={filters.searchTerm}
            onChange={(e) => handleFilterChange(e)}
            name="searchTerm"
          />
          <SearchButton onClick={() => {}}>
            <Search size={18} />
            <SearchButtonText>Search</SearchButtonText>
          </SearchButton>
        </SearchBar>

        <ContentWrapper>
          <Sidebar>
            <FilterHeader>
              <h3>Filters</h3>
              {(filters.companies.length > 0 ||
                filters.titles.length > 0 ||
                filters.recommendedSkills.length > 0 ||
                filters.locations.length > 0 ||
                filters.workPolicies.length > 0 ||
                filters.departments.length > 0 ||
                filters.levels.length > 0 ||
                filters.types.length > 0 ||
                filters.salaryMin ||
                filters.salaryMax ||
                filters.willingToSponsor ||
                filters.benefits.length > 0 ||
                sortByDate) && (
                <ClearFilterButton onClick={clearFilters}>
                  Clear all
                </ClearFilterButton>
              )}
            </FilterHeader>

            <CheckboxLabel style={{ padding: '0.5rem' }}>
              <Checkbox
                type="checkbox"
                name="sortByDate"
                checked={sortByDate}
                onChange={handleCheckboxChange}
              />
              Most Recent
            </CheckboxLabel>

            <CheckboxLabel style={{ padding: '0.5rem' }}>
              <Checkbox
                type="checkbox"
                name="favoritedCompanies"
                checked={showFavoritedCompaniesOnly}
                onChange={handleCheckboxChange}
              />
              View Jobs from Favorited Companies
            </CheckboxLabel>

            <FilterCard>
              <CollapsibleFilterSection title="Companies">
                <SearchableCheckboxList
                  options={filterOptions.companies}
                  selectedOptions={filters.companies}
                  onChange={handleFilterChange}
                  name="companies"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Job Titles">
                <SearchableCheckboxList
                  options={filterOptions.titles}
                  selectedOptions={filters.titles}
                  onChange={handleFilterChange}
                  name="titles"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Skills">
                <SearchableCheckboxList
                  options={filterOptions.recommendedSkills}
                  selectedOptions={filters.recommendedSkills}
                  onChange={handleFilterChange}
                  name="recommendedSkills"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Locations">
                <SearchableCheckboxList
                  options={filterOptions.locations}
                  selectedOptions={filters.locations}
                  onChange={handleFilterChange}
                  name="locations"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Remote">
                <SearchableCheckboxList
                  options={workPolicyOptions}
                  selectedOptions={filters.workPolicies}
                  onChange={handleFilterChange}
                  name="workPolicies"
                  hideSearch={true}
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Department">
                <SearchableCheckboxList
                  options={filterOptions.departments}
                  selectedOptions={filters.departments}
                  onChange={handleFilterChange}
                  name="departments"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Industry">
                <SearchableCheckboxList
                  options={filterOptions.industries}
                  selectedOptions={filters.industries}
                  onChange={handleFilterChange}
                  name="industries"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Subindustry">
                <SearchableCheckboxList
                  options={filterOptions.subindustries}
                  selectedOptions={filters.subindustries}
                  onChange={handleFilterChange}
                  name="subindustries"
                />
              </CollapsibleFilterSection>

              <CollapsibleFilterSection title="Job Level">
                <SearchableCheckboxList
                  options={levelOptions}
                  selectedOptions={filters.levels}
                  onChange={handleFilterChange}
                  name="levels"
                  hideSearch={true}
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Type">
                <SearchableCheckboxList
                  options={employmentTypes}
                  selectedOptions={filters.types}
                  onChange={handleFilterChange}
                  name="types"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Salary Range">
                <Input
                  type="number"
                  name="salaryMin"
                  placeholder="Min salary"
                  value={filters.salaryMin}
                  onChange={handleFilterChange}
                />
                <Input
                  type="number"
                  name="salaryMax"
                  placeholder="Max salary"
                  value={filters.salaryMax}
                  onChange={handleFilterChange}
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Benefits">
                <SearchableCheckboxList
                  options={benefitCategories.map((b) => b.label)}
                  selectedOptions={filters.benefits}
                  onChange={handleFilterChange}
                  name="benefits"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Visa Sponsorship">
                <WillingToSponsorFilter
                  value={filters.willingToSponsor}
                  onChange={handleFilterChange}
                />
              </CollapsibleFilterSection>
              <SaveFilterButton onClick={() => setIsModalOpen(true)}>
                <Heart size={18} />
                Save Filter
              </SaveFilterButton>

              {/* Add Saved Filters section */}
              {savedFilters.length > 0 && (
                <CollapsibleFilterSection
                  title={`Saved Filters (${savedFilters.length})`}
                >
                  {savedFilters.map((filter) => (
                    <SavedFilterItem key={filter.id}>
                      <span
                        onClick={() => applyFilter(filter)}
                        style={{
                          flex: 1,
                          fontSize: '0.875rem',
                          color: '#4A5568'
                        }}
                      >
                        {filter.name}
                      </span>
                      <DeleteButton
                        onClick={(e) => deleteFilter(filter.id, e)}
                        title="Delete filter"
                      >
                        <X size={16} />
                      </DeleteButton>
                    </SavedFilterItem>
                  ))}
                </CollapsibleFilterSection>
              )}
            </FilterCard>
          </Sidebar>

          <JobList>
            <JobListHeader>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%' // Ensure it spans the full width
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2>Available Jobs ({numJobs})</h2>
                  <JobMetadata>
                    {filteredJobs.length === numJobs
                      ? ''
                      : `${filteredJobs.length} ${
                          filteredJobs.length === 1
                            ? 'job matches'
                            : 'jobs match'
                        } criteria`}
                  </JobMetadata>
                </div>

                {userProfile?.plan === 'paid' && (
                  <TooltipContainer
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '-80px',

                      marginLeft: 'auto' // Push the ProIcon to the right
                    }}
                  >
                    <ProIcon
                      src="/images/arena-pro.png"
                      alt="Arena Pro"
                      title="Arena Pro"
                    />
                    <TooltipText>
                      With Arena Pro’s Smart Job Matching, you can cut through
                      the noise and find a job that truly matches your goals and
                      skills.
                    </TooltipText>
                  </TooltipContainer>
                )}
              </div>
            </JobListHeader>
            {loading ? (
              <>
                {[...Array(3)].map((_, i) => (
                  <SkeletonCard key={i}>
                    <SkeletonContent>
                      <SkeletonImage />
                      <SkeletonText>
                        <div style={{ width: '33%', height: '24px' }} />
                        <div style={{ width: '25%' }} />
                        <div style={{ width: '40%' }} />
                      </SkeletonText>
                    </SkeletonContent>
                  </SkeletonCard>
                ))}
              </>
            ) : (
              <>
                {paginatedJobs.map((job, index) => (
                  <JobCard
                    key={job.id}
                    job={job}
                    jobSeekerProfile={jobSeekerProfile}
                    employerProfile={job.employerProfile}
                    index={index}
                    isPaidUser={userProfile?.plan === 'paid'}
                  />
                ))}
              </>
            )}
          </JobList>
        </ContentWrapper>
        {isModalOpen && (
          <ModalOverlay>
            <ModalContent>
              <ModalClose
                onClick={() => {
                  setIsModalOpen(false)
                  setFilterName('')
                  setModalError(null)
                }}
              >
                <X size={20} />
              </ModalClose>
              <ModalTitle>Save Current Filter</ModalTitle>
              <Input
                type="text"
                placeholder="Enter a name for your filter"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
              {modalError && (
                <div
                  style={{
                    color: '#e53e3e',
                    marginTop: '0.5rem',
                    fontSize: '0.875rem'
                  }}
                >
                  {modalError}
                </div>
              )}
              <SaveFilterButton onClick={saveCurrentFilter}>
                Save Filter
              </SaveFilterButton>
            </ModalContent>
          </ModalOverlay>
        )}
        {/* Pagination Controls */}
        <PageNavigation>
          <PageButton
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ArrowLeft />
          </PageButton>
          {renderPageNumbers()}
          <PageButton
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <ArrowRight />
          </PageButton>
        </PageNavigation>
      </MainContent>
    </PageWrapper>
  )
}

export default JobSearch
